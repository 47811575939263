import './style.scss'
import { Button, Drawer, Input, message, Modal, Progress, Radio, Space, Spin } from 'antd'
import { RcFile } from 'antd/es/upload'
import Dragger from 'antd/es/upload/Dragger'
import axios from 'axios'
import { FC, useEffect, useRef, useState } from 'react'
import { cmsApi } from '@/api'
import * as homeApi from '@/api/home'
import error1 from '@/assets/error1.png'
import error2 from '@/assets/error2.png'
import error3 from '@/assets/error3.png'
import error4 from '@/assets/error4.png'
import PlanModal from '@/components/PlanModal'
import VideoModal from '@/components/VideoModal'
import { eventTracking } from '@/libs/util'
import { CloudUploadOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons'

interface IProps {
  open: boolean
  onCancel?: () => void
  onOk?: () => void
  group: any
}

const CreatePhotoAvatarDrawer: FC<IProps> = (props) => {
  const { open, group, onCancel, onOk } = props
  const [currentStep, setCurrentStep] = useState(0)
  const [photoFile, setPhotoFile] = useState<RcFile>(undefined as any)
  const [photoUrl, setPhotoUrl] = useState<string>(undefined as any)
  const [photoInfo, setPhotoInfo] = useState<any>({})
  const [percent, setPercent] = useState(0)
  const cancelTokenSource = useRef<any>()
  const [name, setName] = useState('')
  const [title, setTitle] = useState('')
  const [declareChecked, setDeclareChecked] = useState(false)
  const [guideModalOpen, setGuideModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [planModalOpen, setPlanModalOpen] = useState(false)
  const [preview, setPreview] = useState<any>()

  useEffect(() => {
    if (open) {
      setName('')
      restartUpload()
      setDeclareChecked(false)
      showDeclare()
    }
  }, [open])

  useEffect(() => {
    if (photoFile) {
      setCurrentStep(1)
      setPhotoUrl(URL.createObjectURL(photoFile))
    } else {
      setPhotoUrl(undefined as any)
    }
  }, [photoFile])

  useEffect(() => {
    if (group?.id) {
      setTitle(`${group.title}-形象${(group.member_coun || 0) + 1}`)
    }
  }, [group])

  const onGuideModalClose = () => {
    setGuideModalOpen(false)
  }

  const beforeVideoUpload = async (file: RcFile) => {
    return new Promise((resolve) => {
      const type = file.name.split(/\./)?.at(-1)?.toLocaleLowerCase() as string
      if (['png', 'jpg', 'jpeg'].includes(type)) {
        const size = file?.size || 0
        if (size / 1000 / 1000 > 10) {
          message.warning('照片大小不能大于 10MB')
          resolve(false)
        }
      } else {
        message.warning('请上传png、jpg、jpeg格式照片')
        resolve(false)
      }
      resolve(true)
    })
  }

  const onDrop = async (file: RcFile) => {
    const type = file.name.split(/\./)?.at(-1)?.toLocaleLowerCase() as string
    if (!['png', 'jpg', 'jpeg'].includes(type)) {
      message.warning('请上传png、jpg、jpeg格式照片')
    }
  }

  // media_type -  0 未定义  1 视频  2 音频 3 图片
  const uploadFile = async (file: RcFile, media_type: 0 | 1 | 2 | 3) => {
    try {
      const segs = (file.name || '').split(/\./)
      const { upload_url, oss_key, content_type } =
        (await cmsApi.post('upload_url', {
          extension: segs[segs.length - 1],
          media_type
        })) || {}
      if (!upload_url) {
        throw new Error('failed to upload file')
      }

      cancelTokenSource.current = axios.CancelToken.source()
      setPercent(0)
      setCurrentStep(1)

      await cmsApi.upload(upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''), file, {
        onUploadProgress: (progress) => {
          const percent = Math.round((progress.progress || 0) * 100)
          setPercent(percent)
        },
        headers: {
          'Content-Type': content_type
        },
        cancelToken: cancelTokenSource.current?.token
      })

      setCurrentStep(2)
      setPhotoInfo({
        title: segs[0],
        oss_key
      })
    } catch (err: any) {
      if (err?.code !== 'ERR_CANCELED') {
        message.error(err?.message || err)
      }
    }
  }

  const restartUpload = () => {
    cancelUpload()
    setCurrentStep(0)
    setPercent(0)
    setPhotoFile(undefined as any)
    setPhotoInfo(undefined)
  }

  const cancelUpload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.current?.cancel('取消上传')
    }
  }

  const showDeclare = () => {
    let index = 3

    const instance = Modal.confirm({
      width: 600,
      title: '使用者承诺须知',
      content: (
        <>
          <div>
            本声明将帮助您更好的在【飞影数字人】平台（下称“本平台”）使用相关工具上传和管理您的作品。您若上传作品，即视为您已充分知悉并充分接受以下内容：
          </div>
          <ul className="declare-list">
            <li>您作为使用者在本平台上传、发布的作品，应具有独立、完整的知识产权，不得侵犯他人知识产权等任何权利。</li>
            <li>
              您在使用本平台及上传、发布作品时，应当自觉遵守国家法律、法规，遵守公共秩序，尊重社会公德、社会主义制度、国家利益、公民合法权益、道德风尚和信息真实性等要求。如有违反，一经本平台发现将根据违规程度采取包括但不限于删除、下架、禁止发布内容、封禁账号等处理方式。如造成恶劣影响或涉嫌违法犯罪的，本平台将有权向有关管理机关或公安机关提交相关内容，并配合进行调查。
            </li>
            <li>
              若您上传的作品及作品中的素材（包括但不限于创意、文本、肖像、音频、图片、视频等）侵犯了任何第三方权利，本平台均有权在收到相关侵权投诉后对该被投诉的作品或用户账号依据相应规则，采取包括但不限于
              <label className="red">下架、警告、封禁账号</label>等处理方式。
            </li>
            <li>
              请勿使用我们的服务克隆或生成任何侵犯版权、违反道德伦理、或违反中华人民共和国法律法规的内容。我们生成的所有内容均带有详细日志，自动/人工复审，以及
              可溯源的隐形视频/音频水印，
              <label className="red">若发现您违反了相关规则，我们保留终止您的服务并上报公安机关等机构的权利。</label>
            </li>
            <li>
              更多信息请参阅
              <a target="_blank" href="/eula.html">
                用户协议
              </a>
              、
              <a target="_blank" href="/privacy_agreement.html">
                隐私协议
              </a>
              。
            </li>
          </ul>
        </>
      ),

      okText: (
        <div>
          我已知晓，同意<label style={{ display: 'inline-block', width: 36 }}>（{index}s）</label>
        </div>
      ),
      cancelText: '取消',
      okButtonProps: {
        disabled: true
      },
      onOk: () => {
        setDeclareChecked(true)
      },
      onCancel: () => {
        setDeclareChecked(false)
        onCancel?.()
      }
    })

    const si = setInterval(() => {
      index = index - 1
      instance.update({
        okText: (
          <div>
            我已知晓，同意{index > 0 ? <label style={{ display: 'inline-block', width: 36 }}>（{index}s）</label> : ''}
          </div>
        )
      })
      if (index < 1) {
        instance.update({
          okButtonProps: {
            disabled: false
          }
        })
        clearInterval(si)
      }
    }, 1000)
  }

  const completeCreate = async () => {
    if (!name?.trim() && !group?.id) {
      return message.warning('请输入数字人的名字')
    }

    if (!photoInfo?.oss_key) {
      return message.warning('请上传图片')
    }

    eventTracking('InstantCloneConfirm')
    setLoading(true)
    try {
      const params: any = {
        oss_key: photoInfo?.oss_key,
        with_group: {
          new_group_title: name
        },
        source_type: 3
      }

      if (group?.id) {
        params.title = title
        params.with_group = {
          group_id: group.id
        }
      }
      await homeApi.addDigitalHumans(params)
      message.success('提交成功')
      onCancel?.()
      onOk?.()
    } catch (error: any) {
      if (error.code === 1014) {
        Modal.confirm({
          title: '数字人复刻到达数量上限',
          content: <div>免费用户数字人复刻的创建次数上限为5，成为会员后解锁更多创建次数</div>,
          okText: '开通会员',
          cancelText: '取消',
          onOk: () => {
            setPlanModalOpen(true)
          }
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Drawer
      className="create-photo-drawer"
      open={open}
      title="图片生成数字人"
      width={740}
      onClose={onCancel}
      footer={
        <>
          <div className="declare">
            <Radio checked={declareChecked} onClick={() => setDeclareChecked(!declareChecked)}></Radio>
            我已阅读并同意
            <label className="link" onClick={showDeclare}>
              《使用者承诺须知》
            </label>
          </div>
          <Space>
            <Button onClick={onCancel}>取消</Button>
            <Button disabled={!declareChecked} type="primary" loading={loading} onClick={completeCreate}>
              {loading ? '复刻中' : '提交'}
            </Button>
          </Space>
        </>
      }
    >
      <Spin spinning={loading} tip={'数字人复刻中'}>
        <div className="main">
          <div className="form-item">
            <div className="title">数字人名称</div>
            <Input
              maxLength={20}
              placeholder="请输入数字人名称"
              value={group?.title || name}
              onChange={(e) => setName(e.target.value)}
              disabled={group?.id}
            />
          </div>

          {group?.id && (
            <div className="form-item">
              <div className="title">形象名称</div>
              <Input
                maxLength={20}
                placeholder="请输入形象名称"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          )}
          <div className="form-item">
            <div className="title">
              上传图片
              <ExclamationCircleFilled
                onClick={() => {
                  eventTracking('InstantCloneTips')
                  setGuideModalOpen(true)
                }}
              />
            </div>

            <div className="warning">
              <h4>图片要求</h4>
              <div className="desc">
                <div>
                  <label className="label">人物：</label>
                  <label>正面、半身</label>
                </div>
                <div>
                  <label className="label">格式：</label>
                  <label>png/jpg/jpeg</label>
                </div>
                <div>
                  <label className="label">限制：</label>
                  <label>不支持多人、人脸无遮挡</label>
                </div>
                <div className="w200">
                  <label className="label">大小：</label>
                  <label>不超过10MB，小于4000px</label>
                </div>
              </div>
            </div>
            {currentStep === 0 && (
              <>
                <div className="content">
                  <Dragger
                    accept=".png,.PNG,.jpg,.JPG,.jepg,.JPEG"
                    showUploadList={false}
                    beforeUpload={async (file) => {
                      const flag = (await beforeVideoUpload(file)) as any
                      if (flag) {
                        setPhotoFile(file)
                        uploadFile(file, 3)
                      }
                      return flag
                    }}
                    onDrop={(e) => onDrop(e.dataTransfer.files?.[0] as any)}
                  >
                    <p className="ant-upload-drag-icon">
                      <CloudUploadOutlined />
                    </p>
                    <p className="ant-upload-text">请上传一张照片，用于生成照片数字人</p>
                    <p className="ant-upload-hint">将文件拖到此处，或点击此区域上传</p>
                  </Dragger>
                </div>
              </>
            )}
            {currentStep === 1 && (
              <div className="step-progress">
                <div className="step-progress-content">
                  <div className="percent">{percent}%</div>
                  <Progress percent={percent} showInfo={false} />
                  <div className="tips">照片上传中</div>
                </div>
                <div className="btns">
                  <Button onClick={restartUpload}>取消</Button>
                </div>
              </div>
            )}
            {currentStep === 2 && (
              <div className="step-view">
                <div className="step-view-box video">
                  <img src={photoUrl} />
                  <div className="trash" onClick={restartUpload}>
                    <DeleteOutlined />
                  </div>
                </div>
                <div className="btns">
                  <div onClick={restartUpload}>重新上传</div>
                </div>
              </div>
            )}
          </div>

          <Modal
            title="图片要求"
            open={guideModalOpen}
            footer={
              <Button type="primary" onClick={onGuideModalClose}>
                知道了
              </Button>
            }
            closeIcon={false}
            width={580}
            onCancel={onGuideModalClose}
          >
            <div className="modal-guide">
              <ul>
                <li>
                  <label>1.</label>确保人物正面
                </li>
                <li>
                  <label>2.</label>不支持多人，请确保图片中只有一张人脸
                </li>
                <li>
                  <label>3.</label>人脸不要太小，建议人脸宽度占整体画面宽度的1/4以上
                </li>
                <li>
                  <label>4.</label>人脸不要太大，确保整张人脸都在屏幕区域内，人脸不要出屏幕
                </li>
                <li>
                  <label>5.</label>确保面部特征没有被遮挡，并努力让面部清晰可见
                </li>
                <li>
                  <label>6.</label>图片大小不超过10MB，长宽需小于4000px
                </li>
              </ul>
              <div className="guide-error">
                <div className="title-error">错误示例</div>
                <div className="error-list">
                  <div className="item">
                    <img className="img1" src={error1} />
                    <div>多张人脸</div>
                  </div>
                  <div className="item">
                    <img className="img2" src={error2} />
                    <div>人脸太大</div>
                  </div>
                  <div className="item">
                    <img className="img3" src={error3} />
                    <div>脸部遮挡</div>
                  </div>
                  <div className="item">
                    <img className="img3" src={error4} />
                    <div>检测不到人脸</div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </Spin>

      <PlanModal open={planModalOpen} onCancel={() => setPlanModalOpen(false)} />

      <VideoModal preview={preview} onCancel={() => setPreview(undefined)} />
    </Drawer>
  )
}

export default CreatePhotoAvatarDrawer

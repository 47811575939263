import './style.scss'
import { Button, Carousel, InputNumber, message, Modal, Popover } from 'antd'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Api from '@/api/home'
import { Play } from '@/assets/svg'
import VideoModal from '@/components/VideoModal'
import { IUserPackage, UserStore } from '@/global-states'
import { eventTracking, urlSource } from '@/libs/util'
import { CaretLeftOutlined, CaretRightOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

dayjs.extend(duration)

interface IProps {
  open: boolean
  marketDetail: any
  onCancel?: () => void
}

const MarketModal: FC<IProps> = (props) => {
  const navigate = useNavigate()
  const { marketDetail, onCancel, open } = props
  const [preview, setPreview] = useState<any>()
  const [market, setMarket] = useState<any>({})
  const [price, setPrice] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const [coundownTime, setCoundownTime] = useState(1)
  const timeRef = useRef<any>()
  const [imageCurrent, setImageCurrent] = useState(0)
  const [videoCurrent, setVideoCurrent] = useState(0)
  const topBidding = useMemo(() => {
    if (market?.top_bidding_list?.length) {
      return market?.top_bidding_list[0]
    }
    return undefined
  }, [market])

  const formattedCoundownTime = useMemo(() => {
    const duration = dayjs.duration(coundownTime, 'seconds')
    return {
      days: duration.days().toString().padStart(2, '0'),
      hours: duration.hours().toString().padStart(2, '0'),
      minutes: duration.minutes().toString().padStart(2, '0'),
      seconds: duration.seconds().toString().padStart(2, '0')
    }
  }, [coundownTime])

  const trackData = useMemo(() => {
    return {
      id: marketDetail?.id,
      name: marketDetail?.name
    }
  }, [marketDetail])

  useEffect(() => {
    if (coundownTime <= 0) {
      setMarket({
        ...(market || {}),
        status: 2
      })
      getDetail()
    }
  }, [coundownTime])

  useEffect(() => {
    if (open) {
      if (marketDetail?.type === 2) {
        getDetail()
      }
      if (marketDetail?.type === 1) {
        setMarket(marketDetail)
      }
    }
  }, [open])

  useEffect(() => {
    return () => clearTimer()
  }, [])

  const clearTimer = () => {
    if (timeRef.current) {
      clearInterval(timeRef.current)
      timeRef.current = undefined
    }
  }

  const playVideo = (url: string) => {
    eventTracking('DigitalAvatarVideo', trackData)
    setPreview({ url: url })
  }

  const getDetail = async () => {
    if (!marketDetail.id) return
    const res = await Api.getMerchandisesDetail(marketDetail.id)

    if (res?.auction) {
      res.auction = {
        ...res.auction,
        ...(res.digital_human_merchandise || {})
      }
    }
    const status = res?.auction?.status

    if (status === 0) {
      setCoundownTime(res?.auction?.start_time - res.server_time)
    }
    if (status === 1) {
      setCoundownTime(res?.auction?.end_time - res.server_time)
    }

    setPrice(Math.min((res?.auction?.highest_bid_price || res?.auction?.starting_price || 0) + 100, 999999))
    setMarket({
      ...(res?.auction || {}),
      type: 2
    })

    if (status !== 2) {
      clearTimer()
      timeRef.current = setInterval(() => {
        setCoundownTime((time) => time - 1)
      }, 1000)
    }
  }

  const onClose = () => {
    setMarket(undefined)
    setImageCurrent(0)
    setVideoCurrent(0)
    onCancel?.()
  }

  const submit = async () => {
    eventTracking('DigitalAvatarQuote', trackData)
    setLoading(true)
    try {
      const res: IUserPackage = await UserStore.updateUserPackage()
      const totalPoint = (res.membership_credits || 0) + (res.permanent_credits || 0) + (res.free_credits || 0)

      if (price > totalPoint) {
        return message.error('报价失败，账户积分余额不足')
      }
      await Api.avatarBidding(market.id, {
        price
      })
      message.success('报价成功')
      getDetail()
    } finally {
      setLoading(false)
    }
  }

  const buyNow = async () => {
    eventTracking('DigitalAvatarOneQuote', trackData)
    setLoading(true)

    let totalPoint = 0
    try {
      const userPackage: IUserPackage = await UserStore.updateUserPackage()
      totalPoint =
        (userPackage.membership_credits || 0) + (userPackage.permanent_credits || 0) + (userPackage.free_credits || 0)
    } finally {
      setLoading(false)
    }

    Modal.confirm({
      title: '积分消耗确认',
      content: (
        <div>
          <div>你的积分余额：{totalPoint}积分</div> <div>本次消耗：{market.price}积分</div>
        </div>
      ),
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        await Api.buyMerchandises(market.id)
        Modal.warning({
          title: '雇佣完成',
          content: (
            <div>
              恭喜成功雇佣数字演员，前往
              <a
                style={{ textDecoration: 'underline', padding: '0 2px' }}
                onClick={() => {
                  Modal.destroyAll()
                  navigate('/home')
                }}
              >
                我的数字人
              </a>
              页面查看！
            </div>
          ),
          okText: '好的',
          onOk: () => {
            Modal.destroyAll()
            navigate('/home')
          }
        })
      }
    })
  }

  if (!market) return null

  return (
    <>
      <Modal
        maskClosable={true}
        title={null}
        open={open}
        width={'880px'}
        footer={null}
        onCancel={onClose}
        className="commom-modal market-modal"
        closeIcon={null}
      >
        <div className="market-modal-content">
          <div className="modal-content-main">
            <div className="header">
              <div className="header-left">
                <div className="box">
                  {market?.covers && <img className={`${market?.name}`} src={market?.covers?.[0]} />}
                </div>
              </div>
              <div className="header-info">
                <div className="name">
                  {market?.name}
                  {market?.special_cost && (
                    <Popover
                      content={
                        <div className="common-popover w400" onClick={(e) => e.stopPropagation()}>
                          <div>
                            <strong>创作优惠：</strong>
                          </div>
                          <ul>
                            <li>
                              标有“创作优惠”的数字演员。雇佣后，在创作视频时享受积分消耗优惠（
                              {market.creation_cost_per_second} 积分/秒），即：
                              <div className="ml10">
                                a. 使用音频驱动生成视频时：{market.creation_cost_per_second} 积分/秒；
                              </div>
                              <div className="ml10">
                                b. 使用公版声音、声音克隆-基础版生成视频时：{market.creation_cost_per_second + 1}{' '}
                                积分/秒；
                              </div>
                              <div className="ml10">
                                c. 使用声音克隆-高保真生成视频时：{market.creation_cost_per_second + 2} 积分/秒；
                              </div>
                            </li>
                            <li>仅从数字市场雇佣才有“创作优惠”标记，其他方式上传的视频均无法享有；</li>
                          </ul>
                        </div>
                      }
                      placement="bottom"
                    >
                      <div className="discount" onClick={(e) => e.stopPropagation()}>
                        创作优惠
                        <ExclamationCircleOutlined />
                      </div>
                    </Popover>
                  )}
                </div>
                <div className="desc">{market?.description}</div>
                {market?.scene && <div className="scene">适合场景：{market?.scene}</div>}
              </div>

              {market.type === 2 ? (
                <div className="header-right">
                  <div className="right-info">
                    {market.status === 0 && (
                      <div className="count-down">
                        <span className="gray">距开始：</span>
                        <span className="time">
                          <strong>{formattedCoundownTime.days}</strong>天<strong>{formattedCoundownTime.hours}</strong>
                          时<strong>{formattedCoundownTime.minutes}</strong>分
                          <strong>{formattedCoundownTime.seconds}</strong>秒
                        </span>
                      </div>
                    )}

                    {market.status === 1 && (
                      <div className="count-down">
                        <span className="gray">距结束：</span>
                        <span className="time">
                          <strong>{formattedCoundownTime.days}</strong>天<strong>{formattedCoundownTime.hours}</strong>
                          时<strong>{formattedCoundownTime.minutes}</strong>分
                          <strong>{formattedCoundownTime.seconds}</strong>秒
                        </span>
                      </div>
                    )}

                    {market.status === 2 && (
                      <div className="count-down">
                        <span className="gray">结束时间：</span>
                        {dayjs(market.end_time * 1000).format('YYYY-MM-DD HH:mm')}
                      </div>
                    )}

                    <div className="start-price">
                      <span className="gray">起拍价：</span>
                      <label>{market.starting_price}积分</label>
                    </div>
                    <div className="current-price">
                      <span className="gray">当前价：</span>
                      <label className="mount">{market.highest_bid_price || market.starting_price}积分</label>
                      {!!topBidding && (
                        <span className="tag">
                          出价人：<label>{topBidding.account}</label>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="right-price">
                    <div className="current-price">
                      <span className="gray">出价：</span>

                      <InputNumber
                        disabled={market.status !== 1}
                        min={market.highest_bid_price || market.starting_price}
                        max={999999}
                        value={price}
                        step={100}
                        precision={0}
                        onChange={(num) => setPrice(num)}
                      />
                    </div>

                    {market.status === 0 && (
                      <Button type="primary" disabled danger>
                        即将开始
                      </Button>
                    )}

                    {market.status === 1 && (
                      <Button type="primary" danger onClick={submit} loading={loading}>
                        提交报价
                      </Button>
                    )}

                    {market.status === 2 && (
                      <Button type="primary" disabled danger>
                        已被雇佣
                      </Button>
                    )}
                  </div>
                </div>
              ) : (
                <div className="header-right">
                  <div className="right-price">
                    <div className="current-price">
                      <span className="gray">一口价：</span>
                      <label className="mount">{market.price}积分</label>
                    </div>

                    {market.status === 1 && (
                      <Button type="primary" danger onClick={buyNow} loading={loading}>
                        立即雇佣
                      </Button>
                    )}

                    {market.status === 2 && (
                      <Button type="primary" disabled danger>
                        已被雇佣
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="image-list">
              <Carousel
                infinite={false}
                arrows={true}
                prevArrow={imageCurrent < 1 ? <></> : <CaretLeftOutlined />}
                nextArrow={imageCurrent + 1 < Math.ceil(market.images?.length / 4) ? <CaretRightOutlined /> : <></>}
                dots={false}
                afterChange={(current: number) => {
                  setImageCurrent(current)
                }}
              >
                {market?.images?.map(
                  (image: any, index: number) =>
                    index % 5 === 0 && (
                      <div className="carousel-images" key={image}>
                        <div className="img">
                          <img src={image} className={`${market.name}-${index}`} />
                        </div>
                        {market.images[index + 1] && (
                          <div className="img">
                            <img src={market.images[index + 1]} className={`${market.name}-${index + 1}`} />
                          </div>
                        )}
                        {market.images[index + 2] && (
                          <div className="img">
                            <img src={market.images[index + 2]} className={`${market.name}-${index + 2}`} />
                          </div>
                        )}
                        {market.images[index + 3] && (
                          <div className="img">
                            <img src={market.images[index + 3]} className={`${market.name}-${index + 3}`} />
                          </div>
                        )}
                        {market.images[index + 4] && (
                          <div className="img">
                            <img src={market.images[index + 4]} className={`${market.name}-${index + 4}`} />
                          </div>
                        )}
                      </div>
                    )
                )}
              </Carousel>
            </div>

            <div className="video-list">
              <div className="list-title">视频素材（{market?.videos?.length}）</div>
              <Carousel
                infinite={false}
                arrows={true}
                prevArrow={videoCurrent < 1 ? <></> : <CaretLeftOutlined />}
                nextArrow={videoCurrent + 1 < Math.ceil(market.videos?.length / 3) ? <CaretRightOutlined /> : <></>}
                dots={false}
                afterChange={(current: number) => {
                  if (videoCurrent < current) {
                    eventTracking('DigitalAvataRightClick', trackData)
                  } else {
                    eventTracking('DigitalAvataLeftClick', trackData)
                  }
                  setVideoCurrent(current)
                }}
              >
                {market?.videos?.map(
                  (video: any, index: number) =>
                    index % 3 === 0 && (
                      <div className="carousel-videos" key={index}>
                        <div className="video" onClick={playVideo.bind(this, video)}>
                          <img src={urlSource(video, 'video')} />
                          <div className="play">
                            <Play />
                          </div>
                          <div
                            className="bg"
                            style={{
                              backgroundImage: `url(${urlSource(video, 'video')})`
                            }}
                          ></div>
                        </div>

                        {market.videos[index + 1] && (
                          <div className="video" onClick={playVideo.bind(this, market.videos[index + 1])}>
                            <img src={urlSource(market.videos[index + 1], 'video')} />
                            <div className="play">
                              <Play />
                            </div>
                            <div
                              className="bg"
                              style={{
                                backgroundImage: `url(${urlSource(market.videos[index + 1], 'video')})`
                              }}
                            ></div>
                          </div>
                        )}
                        {market.videos[index + 2] && (
                          <div className="video" onClick={playVideo.bind(this, market.videos[index + 2])}>
                            <img src={urlSource(market.videos[index + 2], 'video')} />
                            <div className="play">
                              <Play />
                            </div>
                            <div
                              className="bg"
                              style={{
                                backgroundImage: `url(${urlSource(market.videos[index + 2], 'video')})`
                              }}
                            ></div>
                          </div>
                        )}

                        {market.videos[index + 3] && (
                          <div className="video" onClick={playVideo.bind(this, market.videos[index + 3])}>
                            <img src={urlSource(market.videos[index + 3], 'video')} />
                            <div className="play">
                              <Play />
                            </div>
                            <div
                              className="bg"
                              style={{
                                backgroundImage: `url(${urlSource(market.videos[index + 3], 'video')})`
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    )
                )}
              </Carousel>
            </div>

            {market.type === 2 ? (
              <div className="rules">
                <div className="rules-title">雇佣规则</div>
                <ul>
                  <li>
                    <div className="left">
                      <strong>1.</strong>
                    </div>
                    <div className="right">
                      <strong>竞拍雇佣：</strong>
                      市场中每位数字演员有起拍价，用户都可以出价竞拍，没有出价次数限制。如果竞拍成功，系统会自动从用户账号中扣除对应的出价积分，对应的数字演员也会自动归属到该账号。
                      <div className="red">
                        请您保证竞拍结束前，账号有足额积分。如果竞拍成功，账号内积分不足，则视为弃权，数字演员会自动流转至出价第二名
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="left">
                      <strong>2.</strong>
                    </div>
                    <div className="right">
                      <strong>关于数字演员：</strong>
                      <div>数字演员由AI生成，独一无二；</div>
                      <div>雇佣后没有使用期限限制</div>
                    </div>
                  </li>
                  <li>
                    <div className="left">
                      <strong>3.</strong>
                    </div>
                    <div className="right">
                      <strong>关于出价积分：</strong>
                      <div>出价积分不能低于数字演员的起拍价；</div>
                      <div>出价时不会扣除积分，公示结果后如果竞拍成功，才会扣除您的出价积分</div>
                    </div>
                  </li>
                  {market?.special_cost && (
                    <li>
                      <div className="left">
                        <strong>4.</strong>
                      </div>
                      <div className="right">
                        <strong>创作优惠：</strong>
                        <div>
                          标有“创作优惠”的数字演员，雇佣后，在创作视频时享受积分消耗优惠（
                          {market.creation_cost_per_second} 积分/秒），即：
                        </div>
                        <div className="ml10">
                          a. 使用音频驱动生成视频时：{market.creation_cost_per_second} 积分/秒；
                        </div>
                        <div className="ml10">
                          b. 使用公版声音、声音克隆-基础版生成视频时：{market.creation_cost_per_second + 1} 积分/秒；
                        </div>
                        <div className="ml10">
                          c. 使用声音克隆-高保真生成视频时：{market.creation_cost_per_second + 2}积分/秒；
                        </div>
                        <div>仅从数字市场雇佣才有“创作优惠”标记，其他方式上传的视频均无法享有；</div>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            ) : (
              <div className="rules">
                <div className="rules-title">雇佣说明</div>
                <ul>
                  <li>
                    <div className="left">
                      <strong>1.</strong>
                    </div>
                    <div className="right">
                      <strong>立即雇佣：</strong>
                      <div>“一口价”无需竞拍，直接雇佣专属数字演员</div>
                    </div>
                  </li>

                  <li>
                    <div className="left">
                      <strong>2.</strong>
                    </div>
                    <div className="right">
                      <strong>关于数字演员：</strong>
                      <div>数字演员由AI生成，独一无二；</div>
                      <div>雇佣后没有使用期限限制</div>
                    </div>
                  </li>

                  {market?.special_cost && (
                    <li>
                      <div className="left">
                        <strong>4.</strong>
                      </div>
                      <div className="right">
                        <strong>创作优惠：</strong>
                        <div>
                          标有“创作优惠”的数字演员，雇佣后，在创作视频时享受积分消耗优惠（
                          {market.creation_cost_per_second} 积分/秒），即：
                        </div>
                        <div className="ml10">
                          a. 使用音频驱动生成视频时：{market.creation_cost_per_second} 积分/秒；
                        </div>
                        <div className="ml10">
                          b. 使用公版声音、声音克隆-基础版生成视频时：{market.creation_cost_per_second + 1} 积分/秒；
                        </div>
                        <div className="ml10">
                          c. 使用声音克隆-高保真生成视频时：{market.creation_cost_per_second + 2}积分/秒；
                        </div>
                        <div>仅从数字市场雇佣才有“创作优惠”标记，其他方式上传的视频均无法享有；</div>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>

          <VideoModal preview={preview} onCancel={() => setPreview(undefined)} />
        </div>
      </Modal>
    </>
  )
}

export default memo(MarketModal)

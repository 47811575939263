import './style.scss'
import { Button, Drawer, Dropdown, Image, message, Modal, Radio, Space, Spin, Steps } from 'antd'
import { FC, useEffect, useRef, useState } from 'react'
import * as homeApi from '@/api/home'
import Empty from '@/assets/empty.png'
import IconFace from '@/assets/icon-face.png'
import IconFolder from '@/assets/icon-folder.png'
import { Play } from '@/assets/svg'
import PayPointModal from '@/components/PayPointModal'
import PlanModal from '@/components/PlanModal'
import VideoModal from '@/components/VideoModal'
import { Render, urlSource } from '@/libs/util'
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons'
import CreateActionModal from '../CreateActionModal'
import CreateFaceModal from '../CreateFaceModal'

interface IProps {
  open: boolean
  onCancel?: () => void
  onOk?: () => void
  group: any
}

const steps = [
  {
    key: 0,
    title: '选择人脸'
  },
  {
    key: 1,
    title: '选择动作'
  },
  {
    key: 2,
    title: '融合生成'
  }
]

const CreateAiAvatarDrawer: FC<IProps> = (props) => {
  const { open, group, onCancel, onOk } = props
  const [currentStep, setCurrentStep] = useState(0)
  const [declareChecked, setDeclareChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [planModalOpen, setPlanModalOpen] = useState(false)
  const [payPointModal, setPayPointModalOpen] = useState(false)
  const [faceList, setFaceList] = useState<any[]>([])
  const [faceId, setFaceId] = useState<number>()
  const [tabIndex, setTabIndex] = useState(0)
  const [recommendActions, setRecommendActions] = useState<any[]>([])
  const [myActions, setMyActions] = useState<any[]>([])
  const [actionDirection, setActionDirection] = useState<'landscape' | 'portrait'>('landscape')
  const [actionId, setActionId] = useState<number>()
  const [previewResult, setPreviewResult] = useState<any>()
  const [preview, setPreview] = useState<any>()
  const [createFaceModalOpen, setCreateFaceModalOpen] = useState(false)
  const [createActionModalOpen, setCreateActionModalOpen] = useState(false)
  const timeRef = useRef<any>()
  const pollingTimeRef = useRef<any>()

  useEffect(() => {
    if (open) {
      setDeclareChecked(false)
      getFaceList()
      pollingFaceList()
      showDeclare()
    } else {
      setTimeout(() => {
        setLoading(false)
        setSubmitLoading(false)
        setCurrentStep(0)
        setActionId(undefined as any)
        setFaceId(undefined as any)
      }, 50)
      clearEvent()
    }
  }, [open])

  const getFaceList = async () => {
    const res = await homeApi.getFaceList()
    const creating = (res.list || []).some((l: any) => l.status === 1 || l.status === 2)
    if (!creating) {
      clearEvent()
    }
    setFaceList(res.list || [])
  }

  const pollingFaceList = () => {
    timeRef.current = setInterval(() => {
      getFaceList()
    }, 2000)
  }

  const getRecommendActions = async () => {
    const res = await homeApi.getRecommendMotions({
      face_id: faceId
    })
    setRecommendActions(res.list || [])
    return
  }

  const getMyActions = async () => {
    const res = await homeApi.getMyActions()
    setMyActions(res.list || [])
    return
  }

  const clearEvent = () => {
    if (timeRef.current) {
      clearInterval(timeRef.current)
      timeRef.current = undefined
    }
  }

  const showDeclare = () => {
    let index = 3

    const instance = Modal.confirm({
      width: 600,
      title: '使用者承诺须知',
      content: (
        <>
          <div>
            本声明将帮助您更好的在【飞影数字人】平台（下称“本平台”）使用相关工具上传和管理您的作品。您若上传作品，即视为您已充分知悉并充分接受以下内容：
          </div>
          <ul className="declare-list">
            <li>您作为使用者在本平台上传、发布的作品，应具有独立、完整的知识产权，不得侵犯他人知识产权等任何权利。</li>
            <li>
              您在使用本平台及上传、发布作品时，应当自觉遵守国家法律、法规，遵守公共秩序，尊重社会公德、社会主义制度、国家利益、公民合法权益、道德风尚和信息真实性等要求。如有违反，一经本平台发现将根据违规程度采取包括但不限于删除、下架、禁止发布内容、封禁账号等处理方式。如造成恶劣影响或涉嫌违法犯罪的，本平台将有权向有关管理机关或公安机关提交相关内容，并配合进行调查。
            </li>
            <li>
              若您上传的作品及作品中的素材（包括但不限于创意、文本、肖像、音频、图片、视频等）侵犯了任何第三方权利，本平台均有权在收到相关侵权投诉后对该被投诉的作品或用户账号依据相应规则，采取包括但不限于
              <label className="red">下架、警告、封禁账号</label>等处理方式。
            </li>
            <li>
              请勿使用我们的服务克隆或生成任何侵犯版权、违反道德伦理、或违反中华人民共和国法律法规的内容。我们生成的所有内容均带有详细日志，自动/人工复审，以及
              可溯源的隐形视频/音频水印，
              <label className="red">若发现您违反了相关规则，我们保留终止您的服务并上报公安机关等机构的权利。</label>
            </li>
            <li>
              更多信息请参阅
              <a target="_blank" href="/eula.html">
                用户协议
              </a>
              、
              <a target="_blank" href="/privacy_agreement.html">
                隐私协议
              </a>
              。
            </li>
          </ul>
        </>
      ),

      okText: (
        <div>
          我已知晓，同意<label style={{ display: 'inline-block', width: 36 }}>（{index}s）</label>
        </div>
      ),
      cancelText: '取消',
      okButtonProps: {
        disabled: true
      },
      onOk: () => {
        setDeclareChecked(true)
      },
      onCancel: () => {
        setDeclareChecked(false)
        onCancel?.()
      }
    })

    const si = setInterval(() => {
      index = index - 1
      instance.update({
        okText: (
          <div>
            我已知晓，同意{index > 0 ? <label style={{ display: 'inline-block', width: 36 }}>（{index}s）</label> : ''}
          </div>
        )
      })
      if (index < 1) {
        instance.update({
          okButtonProps: {
            disabled: false
          }
        })
        clearInterval(si)
      }
    }, 1000)
  }

  const toCreateFace = () => {
    setCreateFaceModalOpen(true)
  }

  const selectFace = (id: number) => {
    setFaceId(id)
  }

  const deleteFace = (id: number) => {
    Modal.confirm({
      title: '删除人脸',
      content: '人脸删除后不可恢复，确认删除这个人脸？',
      onOk: async () => {
        await homeApi.deleteFace(id)
        getFaceList()
        message.success('删除成功')
      }
    })
  }

  const selectAction = (id: number, direction: 'landscape' | 'portrait') => {
    setActionId(id)
    setActionDirection(direction)
  }

  const deleteAction = (id: number, e: any) => {
    e.stopPropagation()
    Modal.confirm({
      title: '删除动作',
      content: '动作删除后不可恢复，确认删除这个动作？',
      onOk: async () => {
        await homeApi.deleteAction(id)
        getMyActions()
        message.success('删除成功')
      }
    })
  }

  const nextStep = async () => {
    setTabIndex(0)
    await getRecommendActions()
    setCurrentStep(1)
    getMyActions()
  }

  const uploadAction = () => {
    setCreateActionModalOpen(true)
  }

  const previewCreate = async () => {
    setLoading(true)
    const res = await homeApi.previewDigitalTask({
      face_id: faceId,
      motion_id: actionId
    })
    if (res.task_id) {
      pollingPreviewResult(res.task_id)
    }
  }

  const pollingPreviewResult = (task_id: number) => {
    let num = 1
    pollingTimeRef.current = setInterval(async () => {
      num++
      const res = await homeApi.getPreviewDigitalTaskResult(task_id)
      if (res.status === 2 || num > 10) {
        setLoading(false)
        message.error('生成失败')
        clearInterval(pollingTimeRef.current)
      }
      if (res.status === 3) {
        setLoading(false)
        clearInterval(pollingTimeRef.current)
        setPreviewResult(res)
        setCurrentStep(2)
      }
    }, 2000)
  }

  const previewVideo = (videoUrl: string) => {
    setPreview({
      url: videoUrl,
      title: '预览'
    })
  }

  const reSelect = () => {
    setCurrentStep(0)
    setFaceId(undefined as any)
    setActionId(undefined as any)
  }

  const createAiAvatarConfirm = () => {
    if (!previewResult.total_credits_cost) {
      return createAiAvatar()
    }
    Modal.confirm({
      title: '积分消耗确认',
      content: (
        <div>
          融合生成1个数字演员需要消耗<label className="red">{previewResult.total_credits_cost || 0}积分</label>
          ，请确保你的账户中有足额积分
        </div>
      ),
      okText: '确认购买',
      onOk: () => {
        createAiAvatar()
      }
    })
  }

  const createAiAvatar = async () => {
    setSubmitLoading(true)
    try {
      const params: any = {
        face_id: faceId,
        motion_id: actionId
      }
      if (group?.id) {
        params.with_group = {
          group_id: group?.id
        }
      }
      await homeApi.submitHumanTasks(params)
      message.success('提交成功')
      onCancel?.()
      onOk?.()
    } catch (error: any) {
      if (error.code === 1002) {
        setPayPointModalOpen(true)
      }
      if (error.code === 1003 || error.code === 1004 || error.code === 1005) {
        setPlanModalOpen(true)
      }
    } finally {
      setSubmitLoading(false)
    }
  }

  return (
    <Drawer
      className="create-ai-avatar-drawer"
      open={open}
      title="AI生成数字人"
      width={800}
      onClose={onCancel}
      footer={
        <>
          <div className="declare">
            <Radio checked={declareChecked} onClick={() => setDeclareChecked(!declareChecked)}></Radio>
            我已阅读并同意
            <label className="link" onClick={showDeclare}>
              《使用者承诺须知》
            </label>
          </div>
          <Space>
            {currentStep === 0 && (
              <Button disabled={!faceId} type="primary" onClick={nextStep}>
                下一步
              </Button>
            )}

            {currentStep === 1 && (
              <>
                <Button disabled={loading} type="dashed" onClick={reSelect}>
                  重新选择人脸
                </Button>
                <Button disabled={!actionId || loading} type="primary" onClick={previewCreate}>
                  融合预览（首帧）
                </Button>
              </>
            )}

            {currentStep === 2 && (
              <>
                <div className="fee">
                  <div className="score">
                    {previewResult?.credits_cost_per_second ? (
                      <label className="light">收费标准：{previewResult?.credits_cost_per_second}积分/秒</label>
                    ) : (
                      <label className="light">限时免费</label>
                    )}
                    <label className="through">{previewResult?.origin_credits_cost_per_second}积分/秒</label>
                  </div>
                </div>
                <Button disabled={loading} type="dashed" onClick={reSelect}>
                  重新选择人脸
                </Button>
                <Button
                  disabled={loading || !declareChecked}
                  loading={submitLoading}
                  type="primary"
                  onClick={createAiAvatarConfirm}
                >
                  {submitLoading ? '提交中，请稍等' : '融合生成新的数字人'}
                </Button>
              </>
            )}
          </Space>
        </>
      }
    >
      <Spin spinning={loading} tip={'预览效果生成中'}>
        <div className="main">
          <Steps current={currentStep} items={steps} />
          <div className="main-box">
            {currentStep === 0 && (
              <div className="my-face">
                <div className="box-title ">我的人脸</div>
                <div className="face-list">
                  <div className="list-item">
                    <div className="box box-add" onClick={toCreateFace}>
                      <img className="icon-face" src={IconFace} />
                      <div className="text">捏一个</div>
                    </div>
                  </div>

                  {faceList?.map((l) => (
                    <div className="list-item" key={l.id}>
                      <div className="box">
                        <Image className="img" src={l.image_url} />

                        {l.status !== 1 && (
                          <Dropdown
                            menu={{
                              items: [
                                {
                                  key: '1',
                                  label: (
                                    <div className="dropdown-list" onClick={deleteFace.bind(this, l.id)}>
                                      <DeleteOutlined />
                                      删除
                                    </div>
                                  )
                                }
                              ]
                            }}
                            placement="bottom"
                          >
                            <div className="op" onClick={(e) => e.stopPropagation()}>
                              <div className="dots">...</div>
                            </div>
                          </Dropdown>
                        )}

                        {(l.status === 1 || l.status === 2) && (
                          <div className="mask">
                            <div className="pending">
                              <LoadingOutlined />
                            </div>
                          </div>
                        )}
                      </div>
                      {l.status === 3 && (
                        <div className="select-btn" onClick={selectFace.bind(this, l.id)}>
                          {faceId === l.id ? (
                            <div className="text">已选</div>
                          ) : (
                            <Button type="primary" className="black">
                              选择
                            </Button>
                          )}
                        </div>
                      )}

                      {l.status !== 3 && (
                        <div className="select-btn">
                          <div className="text gray">{l.status === 4 ? '生成失败' : '生成中'}</div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {currentStep === 1 && (
              <div className="my-action">
                <div className="tabs">
                  <div className={`tab-item ${tabIndex === 0 ? 'actived' : ''}`} onClick={() => setTabIndex(0)}>
                    推荐
                  </div>
                  <div className={`tab-item ${tabIndex === 1 ? 'actived' : ''}`} onClick={() => setTabIndex(1)}>
                    我的
                  </div>
                </div>
                <div className="video-list">
                  {tabIndex === 1 && (
                    <div className="list-item" onClick={uploadAction}>
                      <div className="box box-black">
                        <img className="icon-folder" src={IconFolder} />
                        <div className="text">本地上传</div>
                      </div>
                    </div>
                  )}

                  {tabIndex === 0 && !recommendActions?.length ? (
                    <div className="empty">
                      <img className="empty-img" src={Empty} />
                      <div className="desc">
                        <div>暂无推荐数据，</div>
                        <div className="link" onClick={() => setTabIndex(1)}>
                          请自行上传
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {(tabIndex === 0 ? recommendActions : myActions)?.map((action, index) => (
                        <div className="list-item" key={action.id}>
                          {
                            <Render>
                              {function List() {
                                const [direction, setDirection] = useState<'landscape' | 'portrait'>('landscape')
                                return (
                                  <>
                                    <div className="box" onClick={() => previewVideo(action.video_url)}>
                                      <img
                                        className="img"
                                        src={urlSource(action.video_url, 'video')}
                                        onLoad={(e: any) => {
                                          const width = e.target.naturalWidth || 0
                                          const height = e.target.naturalHeight || 0
                                          if (height > width) {
                                            setDirection('portrait')
                                          }
                                        }}
                                      />

                                      <div className="play">
                                        <Play />
                                      </div>

                                      {tabIndex === 1 && (
                                        <Dropdown
                                          menu={{
                                            items: [
                                              {
                                                key: '1',
                                                label: (
                                                  <div
                                                    className="dropdown-list"
                                                    onClick={(e) => deleteAction(action.id, e)}
                                                  >
                                                    <DeleteOutlined />
                                                    删除
                                                  </div>
                                                )
                                              }
                                            ]
                                          }}
                                          placement="bottom"
                                        >
                                          <div className="op" onClick={(e) => e.stopPropagation()}>
                                            <div className="dots">...</div>
                                          </div>
                                        </Dropdown>
                                      )}

                                      <div className="bottom">{action.duration_ms / 1000}秒</div>
                                    </div>
                                    <div className="select-btn" onClick={() => selectAction(action.id, direction)}>
                                      {actionId === action.id ? (
                                        <div className="text">已选</div>
                                      ) : (
                                        <Button type="primary" className="black">
                                          选择
                                        </Button>
                                      )}
                                    </div>
                                  </>
                                )
                              }}
                            </Render>
                          }
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            )}

            {currentStep === 2 && (
              <div className={`my-preview ${actionDirection}`}>
                <Image preview={false} className="preview-img" src={previewResult?.image_url} />
              </div>
            )}
          </div>
        </div>
      </Spin>

      <VideoModal preview={preview} onCancel={() => setPreview(undefined)} />

      <PlanModal open={planModalOpen} onCancel={() => setPlanModalOpen(false)} />

      <PayPointModal open={payPointModal} onCancel={() => setPayPointModalOpen(false)} />

      <CreateFaceModal
        open={createFaceModalOpen}
        onCancel={() => setCreateFaceModalOpen(false)}
        onOk={() => {
          clearEvent()
          getFaceList()
          pollingFaceList()
        }}
      />

      <CreateActionModal
        open={createActionModalOpen}
        onCancel={() => setCreateActionModalOpen(false)}
        onOk={getMyActions}
      />
    </Drawer>
  )
}

export default CreateAiAvatarDrawer

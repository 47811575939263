import './style.scss'
import { FC, memo, PropsWithChildren, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import * as Api from '@/api/home'
import { Home, Video } from '@/assets/svg'
import { Study } from '@/assets/svg/study'
import { User } from '@/assets/svg/user'
import { UserStore } from '@/global-states'
import { urlSource } from '@/libs/util'
import { AppstoreFilled, CopyrightCircleFilled, PayCircleFilled } from '@ant-design/icons'

const LayoutMenu: FC = () => {
  const { pathname } = useLocation()
  const { userPackage, studyCenterClick } = UserStore
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    getData()
  }, [])

  const Item: FC<PropsWithChildren<{ current: string; path: string }>> = (props) => {
    const { children, current, path } = props
    return (
      <Link to={`../${path}`} className="menu-link">
        <div className={`panel ${current === path ? 'selected' : ''}`}>{children}</div>
      </Link>
    )
  }

  const getData = async () => {
    const [merchandises] = await Promise.all([Api.getMerchandisesV2()])
    setData([...(merchandises.list || []).filter((l: any) => l.status !== 2)])
  }

  const jumpToStudyCenter = () => {
    localStorage.setItem('studyCenter', 'true')
    UserStore.studyCenterClick = true
    window.open('/study/index.html')
  }

  return (
    <div className="layout-menu">
      <div className="layout-menu-main">
        <Item current={pathname} path="/home">
          <Home className="icon" />
          数字人
        </Item>
        <Item current={pathname} path="/clone">
          <CopyrightCircleFilled className="clone" />
          声音克隆
        </Item>
        <Item current={pathname} path="/avatar-market">
          <AppstoreFilled className="market" />
          数字市场
          {data.length > 0 && <div className="tag">上新</div>}
        </Item>
        <Item current={pathname} path="/video">
          <Video className="icon" />
          作品管理
        </Item>
        <Item current={pathname} path="/invite">
          <PayCircleFilled className="icon" />
          免费积分
        </Item>
        <a className="menu-link" href="javascript:void(0)" rel="noreferrer" onClick={jumpToStudyCenter}>
          <div className="panel">
            <Study className="study" />
            学习中心
            {!studyCenterClick && <div className="tag">new</div>}
          </div>
        </a>
        <Item current={pathname} path="/setting">
          <User className="icon" />
          个人中心
        </Item>
      </div>

      {userPackage && (
        <>
          {userPackage.membership_expire_time && !userPackage.membership_expired ? (
            <div className="bottom">
              <img className="qrcode" src={urlSource('hiflys/qrcode/qrcode-vip.webp')} />
            </div>
          ) : (
            <div className="bottom">
              <img className="qrcode" src={urlSource('hiflys/qrcode/qrcode.webp')} />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default memo(LayoutMenu)

import './style.scss'
import { Button, Input, message, Modal, Popover } from 'antd'
import dayjs from 'dayjs'
import { FC, memo, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as Api from '@/api/account'
import IconConpon from '@/assets/icon-coupon.png'
import PointBlue from '@/assets/point-blue.webp'
import PointYellow from '@/assets/point-yellow.webp'
import Person from '@/assets/user.png'
import CouponListModal from '@/components/Coupon/list'
import PayPointModal from '@/components/PayPointModal'
import { UserStore } from '@/global-states'
import { eventTracking } from '@/libs/util'
import { InfoCircleOutlined } from '@ant-design/icons'

interface IProps {
  onSub: () => void
  onPaySuccess: () => void
}

const UserDetail: FC<IProps> = (props) => {
  const { onSub, onPaySuccess } = props
  const [searchParams] = useSearchParams()
  const { phone, userPackage } = UserStore
  const [couponListOpen, setCouponListOpen] = useState(false)
  const [convertModalOpen, setConvertModalOpen] = useState(false)
  const [payPointModalOpen, setPayPointModalOpen] = useState(false)
  const [code, setCode] = useState('')

  useEffect(() => {
    if (searchParams.get('code')) {
      onSub()
    }
  }, [searchParams])

  const vip = useMemo(() => {
    return !!userPackage?.membership_expire_time
  }, [userPackage])

  const vipExpired = useMemo(() => {
    return !!userPackage?.membership_expired
  }, [userPackage])

  const totalPoint = useMemo(() => {
    if (!userPackage) return 0
    return (
      (userPackage.membership_credits || 0) + (userPackage.permanent_credits || 0) + (userPackage.free_credits || 0)
    )
  }, [userPackage])

  const useCode = async () => {
    await Api.useCode({ redeem_code: code })
    setConvertModalOpen(false)
    message.success('兑换成功')
    UserStore.updateUserPackage()
  }

  const toPayPoint = () => {
    eventTracking('RechargeCredits')
    setPayPointModalOpen(true)
  }

  const freeToPayPointExpired = () => {
    Modal.confirm({
      title: '温馨提示',
      content: '抱歉，免费用户无法充值积分，开通会员后即可自由充值积分。',
      okText: '开通会员',
      onOk: () => {
        onSub()
      }
    })
  }

  const toPayPointExpired = () => {
    Modal.confirm({
      title: '温馨提示',
      content: '抱歉，您的会员已经过期，无法充值积分，续费会员后可继续充值积分',
      okText: '续费会员',
      onOk: () => {
        onSub()
      }
    })
  }

  return (
    <div className="setting-user-detail">
      <div className="header-user">
        <div className="user">
          <img src={Person} />

          <div className="user-info">
            <div className="phone">
              <label>{phone}</label>
              {vip && !vipExpired && <label className="tag">{userPackage?.current_membership_package_title}会员</label>}
            </div>
            {vip ? (
              <>
                {vipExpired ? (
                  <label className="text red">
                    套餐已于{dayjs(userPackage.membership_expire_time * 1000).format('YYYY-MM-DD HH:mm')}过期
                  </label>
                ) : (
                  <label className="text">
                    到期时间：{dayjs(userPackage.membership_expire_time * 1000).format('YYYY-MM-DD HH:mm')}
                  </label>
                )}
              </>
            ) : (
              <label className="text">未开通会员</label>
            )}
          </div>
        </div>
        {vip ? (
          <div className="btns">
            {vipExpired ? (
              <>
                <Button onClick={toPayPointExpired}>充值积分</Button>
                <Button
                  type="primary"
                  onClick={() => {
                    eventTracking('RenewUpgrade')
                    onSub()
                  }}
                >
                  立即续费
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => {
                    eventTracking('RenewUpgrade')
                    onSub()
                  }}
                >
                  续费 / 升级
                </Button>
                <Button className="point-pay" type="primary" onClick={toPayPoint}>
                  充值积分
                  <div className="tag">特惠</div>
                </Button>
              </>
            )}
          </div>
        ) : (
          <div className="btns">
            <Button onClick={freeToPayPointExpired}>充值积分</Button>
            <Button
              type="primary"
              onClick={() => {
                eventTracking('SubscribeNow')
                onSub()
              }}
            >
              立即订阅
            </Button>
          </div>
        )}
      </div>
      <div className="header-desc">
        <div className="left">
          <label className="text">积分数量</label>
          <label className="num">{totalPoint}</label>
          <label className="icon">
            <Popover
              content={
                <div className="point-popover">
                  <div>
                    <label className="label">赠送积分:</label>
                    <label className="num">{userPackage?.free_credits || 0}</label>
                  </div>
                  <div>
                    <label className="label">会员积分:</label>
                    <label className="num">{userPackage?.membership_credits || 0}</label>
                  </div>
                  <div>
                    <label className="label">充值积分:</label>
                    <label className="num">{userPackage?.permanent_credits || 0}</label>
                  </div>
                </div>
              }
              placement="right"
            >
              <InfoCircleOutlined />
            </Popover>
          </label>
        </div>
        <div className="right">
          <div className="link red" onClick={() => setCouponListOpen(true)}>
            <img src={IconConpon} />
            <a href="javascript:void(0)">我的优惠券</a>
          </div>
          <div
            className="link blue"
            onClick={() => {
              eventTracking('CreditsExplanation')
              window.open('https://lingverse.feishu.cn/sheets/IJeQsahvdhqX5btBSzycBKPRnr0')
            }}
          >
            <img src={PointBlue} />
            <a href="javascript:void(0)">积分说明</a>
          </div>
          <div
            className="link yellow"
            onClick={() => {
              eventTracking('CreditsRedemption')
              setCode('')
              setConvertModalOpen(true)
            }}
          >
            <img src={PointYellow} />
            <a href="javascript:void(0)">积分兑换</a>
          </div>
        </div>
      </div>

      <Modal
        okText="立即兑换"
        cancelText="取消"
        title="请输入兑换码"
        onCancel={() => setConvertModalOpen(false)}
        open={convertModalOpen}
        okButtonProps={{ disabled: !code }}
        onOk={useCode}
      >
        <div className="convert-content">
          <Input value={code} onChange={(e) => setCode(e.target.value)} />
        </div>
      </Modal>

      <CouponListModal open={couponListOpen} onCancel={() => setCouponListOpen(false)} toUseCoupon={onSub} />

      <PayPointModal
        open={payPointModalOpen}
        onCancel={() => setPayPointModalOpen(false)}
        onSuccess={() =>
          setTimeout(() => {
            onPaySuccess()
          }, 300)
        }
      />
    </div>
  )
}

export default memo(UserDetail)

/* eslint-disable react/jsx-no-undef */
import './style.scss'
import { Dropdown, Input, message, Modal } from 'antd'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import * as Api from '@/api/home'
import ArrowLeft from '@/assets/arrow-left.png'
import { CreateVideo, Ellipsis, Play } from '@/assets/svg'
import { Arrow } from '@/assets/svg/arrow'
import VideoModal from '@/components/VideoModal'
import { InteractStore } from '@/global-states'
import { urlSource } from '@/libs/util'
import Groups from '@/views/home/components/Groups'
import { CheckOutlined, DeleteOutlined, EditOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import CreateInteractAvatarDrawer from '../CreateInteractAvatarDrawer'

interface IProps {
  open: boolean
  onCancel: () => void
}

const AvatarModal: FC<IProps> = (props) => {
  const { open, onCancel } = props
  const { selectAvatar } = InteractStore
  const tabs = ['我的数字人', '公共数字人']
  const [tabIndex, setTabIndex] = useState<number>()
  const [editId, setEditId] = useState<any>()
  const [preview, setPreview] = useState<any>()
  const [openId, setOpenId] = useState<any>()
  const [data, setData] = useState<any[]>([])
  const [globalData, setGlobalData] = useState<any[]>([])
  const [createDrawerOpen, setCreateDrawerOpen] = useState(false)
  const [group, setGroup] = useState<any>()
  const [avatarId, setAvatarId] = useState<number>()
  const [groupHumans, setGroupHumans] = useState<any[]>([])
  const timeRef = useRef<any>()
  const showData = useMemo(() => {
    if (tabIndex === 0) {
      return data
    }
    if (tabIndex === 1) {
      return globalData
    }
  }, [tabIndex, globalData, data])

  useEffect(() => {
    if (open) {
      getData()
    } else {
      setTabIndex(undefined)
    }
  }, [open])

  useEffect(() => {
    if (group?.id) {
      getGroupHumans(true)
      loopGroupHumans()
    }
  }, [group])

  const getData = async () => {
    const res = await Api.getDigitalList()
    setData((res.list || []).filter((l: any) => !l.is_public))
    setGlobalData((res.list || []).filter((l: any) => l.is_public))
    if (!tabIndex && tabIndex !== 0) {
      const selectData = (res.list || []).find((l: any) => l.id === selectAvatar.group_id)
      if (selectData.is_public) {
        setTabIndex(1)
      } else {
        setTabIndex(0)
      }
    }
  }

  const getGroupHumans = async (check?: boolean) => {
    const res = await Api.getDigitalAvatar(group.id as number)
    setGroupHumans(res.list || [])
    const finishList = (res?.list || []).filter((d: any) => d.generated_status === 2)
    if (check && finishList?.length) {
      InteractStore.selectAvatar = {
        ...finishList[0],
        group_id: group.id,
        group_title: group.name
      }
    }
    if (!(res?.list || []).filter((d: any) => d.generated_status === 1)?.length) {
      clearEvent()
    }
  }

  const loopGroupHumans = () => {
    clearEvent()
    timeRef.current = setInterval(() => {
      getGroupHumans()
    }, 5000)
  }

  const clearEvent = () => {
    if (timeRef.current) {
      clearInterval(timeRef.current)
      timeRef.current = undefined
    }
  }

  const onTabChange = (index: number) => {
    setTabIndex(index)
    setGroup(undefined)
  }

  const previeGroup = (g: any) => {
    setGroup(g)
  }

  const previewVideo = (d: any) => {
    setOpenId(undefined)
    setPreview({
      url: d.video_url,
      title: d.name
    })
  }

  const onValueChange = (e: any) => {
    setGroupHumans(
      groupHumans.map((d) => {
        return d.id === editId
          ? {
              ...d,
              name: e.target.value
            }
          : d
      })
    )
  }

  const onGroupValueChange = (e: any) => {
    setData(
      data.map((d) => {
        return d.id === editId
          ? {
              ...d,
              name: e.target.value
            }
          : d
      })
    )
  }

  const saveChange = async (d: any) => {
    setEditId('')
    if (d.name.trim()) {
      Api.editDigitalAvatar(group.id, d.id, {
        name: d.name
      })
    } else {
      getGroupHumans()
    }
  }

  const saveGroupChange = async (d: any) => {
    setEditId('')
    if (d.name.trim()) {
      Api.editDigital(d.id, {
        name: d.name
      })
    } else {
      getData()
    }
  }

  const renameVideo = (id: any, e: any) => {
    e.stopPropagation()
    setOpenId(undefined)
    setEditId(id)
  }

  const deleteVideo = async (id: any, e: any) => {
    e.stopPropagation()
    Modal.confirm({
      title: '删除形象',
      content: '形象删除后不可恢复，确认删除形象？',
      onOk: async () => {
        setOpenId(undefined)
        await Api.deleteDigitalAvatar(group.id, id)
        getGroupHumans()
        if (id === selectAvatar?.id) {
          InteractStore.initDigital()
        }
        message.success('删除成功')
      }
    })
  }

  const renameGroup = (id: any, e: any) => {
    e.stopPropagation()
    setOpenId(undefined)
    setEditId(id)
  }

  const deleteGroup = (id: any, e: any) => {
    e.stopPropagation()
    Modal.confirm({
      title: '删除数字人',
      content: <div className="red">数字人删除时，会同时删除其关联形象，且不可恢复。确认删除？</div>,
      onOk: async () => {
        setOpenId(undefined)
        await Api.deleteDigital(id)
        getData()
        message.success('删除成功')
      }
    })
  }

  const moveGroup = (id: number, e: any) => {
    e.stopPropagation()
    setAvatarId(id)
  }

  const toCreateAvatar = () => {
    setGroup({
      ...group,
      avatar_count: groupHumans.length
    })
    setCreateDrawerOpen(true)
  }

  const onSelectVideo = (d: any) => {
    InteractStore.selectAvatar = {
      ...d,
      group_id: group.id,
      group_title: group.name
    }
    onCancel()
  }

  const onCreateSuccess = () => {
    if (group?.id) {
      getGroupHumans()
      loopGroupHumans()
    } else {
      getData()
    }
  }

  return (
    <Modal className="tab-modal" open={open} footer={null} width={1020} onCancel={onCancel}>
      <div className="tab-modal-content">
        <div className="tabs">
          {tabs.map((t, index) => (
            <div
              className={`tabs-item ${tabIndex === index ? 'actived' : ''}`}
              key={t}
              onClick={onTabChange.bind(this, index)}
            >
              {t}
            </div>
          ))}
        </div>

        <div className="main">
          <div className="main-wrappper">
            {!group?.id ? (
              <div className="page-container">
                {tabIndex === 0 && (
                  <div className="list-box">
                    <div className="box-main empty-group" onClick={toCreateAvatar}>
                      <div className="center">
                        <div className="t">实时互动数字人</div>
                        <div className="desc ellipsis">快速复刻实时互动数字人</div>
                        <div className="button">
                          快速复刻
                          <Arrow />
                        </div>
                      </div>
                    </div>
                    <div className="box-title"></div>
                  </div>
                )}
                {showData?.map((d) => (
                  <div className="list-box" key={d.id}>
                    <div className="box-main" onClick={() => previeGroup(d)}>
                      <div
                        className="bg"
                        style={{
                          backgroundImage: `url(${d.cover_image_url})`
                        }}
                      ></div>

                      {tabIndex === 0 && (
                        <div className="op">
                          <Dropdown
                            menu={{
                              items: [
                                {
                                  key: '1',
                                  label: (
                                    <div className="dropdown-list" onClick={renameGroup.bind(this, d.id)}>
                                      <EditOutlined />
                                      重命名
                                    </div>
                                  )
                                },
                                {
                                  key: '2',
                                  label: (
                                    <div className="dropdown-list" onClick={deleteGroup.bind(this, d.id)}>
                                      <DeleteOutlined />
                                      删除
                                    </div>
                                  )
                                }
                              ]
                            }}
                            placement="bottom"
                            open={openId === d.id}
                            onOpenChange={(open: boolean) => setOpenId(open ? d.id : undefined)}
                          >
                            <div className="btn" onClick={(e) => e.stopPropagation()}>
                              <Ellipsis />
                            </div>
                          </Dropdown>
                        </div>
                      )}

                      <div className="photo">
                        <img src={d.cover_image_url} />
                      </div>

                      {d.id === selectAvatar?.group_id && (
                        <div className="checked">
                          <CheckOutlined />
                        </div>
                      )}
                    </div>
                    <div className="bottom">
                      {editId === d.id ? (
                        <Input
                          value={d.name}
                          autoFocus
                          onChange={onGroupValueChange}
                          onBlur={saveGroupChange.bind(this, d)}
                          onPressEnter={saveGroupChange.bind(this, d)}
                        />
                      ) : (
                        <div className="box-title">{d.name}</div>
                      )}
                      {!editId && <div className="num">{d.avatar_count || 0}个形象</div>}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>
                <div className="common-title">
                  <img
                    src={ArrowLeft}
                    onClick={() => {
                      setGroup(undefined)
                      setGroupHumans([])
                      getData()
                    }}
                    className="arrow-left"
                  />

                  <div className="text">{group.name}</div>
                </div>
                <div className="page-container">
                  {!group.is_public && (
                    <div className="list-box">
                      <div className="box-main empty" onClick={toCreateAvatar}>
                        <div className="center">
                          <div className="text">
                            <PlusOutlined />
                            创建更多形象
                          </div>
                          <div className="desc">上传更多素材来创建数字人的更多形象</div>
                        </div>
                      </div>
                      <div className="box-title"></div>
                    </div>
                  )}

                  {groupHumans.map((d) => (
                    <div className={`list-box ${openId === d.id ? 'hovered' : ''} `} key={d.id}>
                      <div className="box-main" onClick={() => d.generated_status === 2 && previewVideo(d)}>
                        <div
                          className="bg"
                          style={{
                            backgroundImage: `url(${urlSource(d.video_url, 'video')})`
                          }}
                        ></div>

                        {d.generated_status === 2 && (
                          <div className="play">
                            <Play />
                          </div>
                        )}

                        {d.generated_status !== 1 && !group?.is_public && (
                          <div className="op" onClick={(e) => e.stopPropagation()}>
                            <Dropdown
                              menu={{
                                items: [
                                  {
                                    key: '1',
                                    label: (
                                      <div className="dropdown-list" onClick={renameVideo.bind(this, d.id)}>
                                        <EditOutlined />
                                        重命名
                                      </div>
                                    )
                                  },
                                  // {
                                  //   key: '2',
                                  //   label: (
                                  //     <div className="dropdown-list" onClick={moveGroup.bind(this, d.id)}>
                                  //       <DeliveredProcedureOutlined />
                                  //       移动到
                                  //     </div>
                                  //   )
                                  // },
                                  {
                                    key: '3',
                                    label: (
                                      <div className="dropdown-list" onClick={deleteVideo.bind(this, d.id)}>
                                        <DeleteOutlined />
                                        删除
                                      </div>
                                    )
                                  }
                                ]
                              }}
                              placement="bottom"
                              open={openId === d.id}
                              onOpenChange={(open: boolean) => setOpenId(open ? d.id : undefined)}
                            >
                              <div className="btn" onClick={(e) => e.stopPropagation()}>
                                <Ellipsis />
                              </div>
                            </Dropdown>
                          </div>
                        )}

                        {d.generated_status === 1 && (
                          <div className="mask">
                            <div className="pending">
                              <LoadingOutlined />
                              <div>生成中, 请稍等</div>
                            </div>
                          </div>
                        )}

                        {d.generated_status === 3 && (
                          <div className="status error">
                            <span>生成失败</span>
                          </div>
                        )}

                        <div className="photo">
                          <img src={urlSource(d.video_url, 'video')} />
                        </div>

                        {d.id === selectAvatar?.id && (
                          <div className="checked">
                            <CheckOutlined />
                          </div>
                        )}
                      </div>

                      <div className="bottom">
                        {editId === d.id ? (
                          <Input
                            value={d.name}
                            autoFocus
                            onChange={onValueChange}
                            onBlur={saveChange.bind(this, d)}
                            onPressEnter={saveChange.bind(this, d)}
                          />
                        ) : (
                          <div className="box-title">{d.name}</div>
                        )}
                        {d.generated_status === 2 && (
                          <div className="btn" onClick={onSelectVideo.bind(this, d)}>
                            选用
                            <CreateVideo />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <VideoModal preview={preview} onCancel={() => setPreview(undefined)} btnShow={false} />

      <CreateInteractAvatarDrawer
        open={createDrawerOpen}
        onCancel={() => setCreateDrawerOpen(false)}
        onOk={onCreateSuccess}
        group={group}
      />

      <Groups avatarId={avatarId} onSuccess={getGroupHumans} onCancel={() => setAvatarId(undefined)} />
    </Modal>
  )
}

export default AvatarModal

import './style.scss'
import { message, Spin } from 'antd'
import { RcFile } from 'antd/es/upload'
import Dragger from 'antd/es/upload/Dragger'
import { FC, useState } from 'react'
import { cmsApi, interactApi } from '@/api'
import VideoModal from '@/components/VideoModal'
import { CloudUploadOutlined } from '@ant-design/icons'

interface IProps {
  uploadText?: string
  onUploadSuccess: (data: any) => void
}

const UploadFile: FC<IProps> = (props) => {
  const { onUploadSuccess, uploadText } = props
  const [loading, setLoading] = useState(false)
  const [preview, setPreview] = useState<any>()

  // media_type -  0 未定义  1 视频  2 音频 3 图片 4 文本
  const uploadFile = async (file: RcFile, media_type: 0 | 1 | 2 | 3 | 4) => {
    try {
      const segs = (file.name || '').split(/\./)
      const { upload_url, oss_key, content_type } =
        (await interactApi.post('upload_url', {
          extension: segs[segs.length - 1],
          media_type
        })) || {}
      if (!upload_url) {
        throw new Error('failed to upload file')
      }

      setLoading(true)

      await cmsApi.upload(upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''), file, {
        headers: {
          'Content-Type': content_type
        }
      })

      setLoading(false)
      onUploadSuccess({
        name: segs[0],
        oss_key
      })
    } catch (err: any) {
      if (err?.code !== 'ERR_CANCELED') {
        message.error(err?.message || err)
      }
    }
  }

  const beforeVideoUpload = async (file: RcFile) => {
    return new Promise((resolve) => {
      const type = file.name.split(/\./)?.at(-1)?.toLocaleLowerCase() as string
      if (['csv', 'xlsx'].includes(type)) {
        const size = file?.size || 0
        if (size / 1000 / 1000 > 10) {
          message.warning('文件大小不能大于 10MB')
          resolve(false)
        }
        resolve(true)
      } else {
        message.warning('请上csv、xlsx格式文件')
        resolve(false)
      }
    })
  }
  const onDrop = async (file: RcFile) => {
    const type = file.name.split(/\./)?.at(-1)?.toLocaleLowerCase() as string
    if (!['csv', 'xlsx'].includes(type)) {
      message.warning('请上csv、xlsx格式文件')
    }
  }

  return (
    <>
      <Spin className="form-spin" spinning={loading} tip="上传中">
        <Dragger
          accept=".csv,.xlsx"
          showUploadList={false}
          beforeUpload={async (file) => {
            const flag = (await beforeVideoUpload(file)) as any
            if (flag) {
              uploadFile(file, 3)
            }
            return flag
          }}
          onDrop={(e) => onDrop(e.dataTransfer.files?.[0] as any)}
        >
          <p className="ant-upload-drag-icon">
            <CloudUploadOutlined />
          </p>
          <p className="ant-upload-text">将文件拖到此处，或点击此区域上传</p>
          <p className="ant-upload-hint">{uploadText}</p>
        </Dragger>
      </Spin>

      <VideoModal preview={preview} onCancel={() => setPreview(undefined)} />
    </>
  )
}

export default UploadFile

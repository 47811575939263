import './style.scss'
import { Modal } from 'antd'
import { Buffer } from 'buffer'
import { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import wavConverter from 'wav-converter'
import * as homeApi from '@/api/home'
import ArrowLeft from '@/assets/arrow-left.png'
import AudioTagGood from '@/assets/audio-tag-good-new.png'
import AudioTag from '@/assets/audio-tag-new.png'
import { Play } from '@/assets/svg/play'
import { InteractStore, UserStore } from '@/global-states'
import CloneHighDrawer from '@/views/clone/components/clone-high-drawer'
import { LoadingOutlined, PauseOutlined, PlusCircleFilled } from '@ant-design/icons'

interface IProps {
  open: boolean
  onCancel?: () => void
  upgradePlan?: () => void
}

const VoiceModal: FC<IProps> = (props) => {
  const { open, onCancel, upgradePlan } = props
  const { selectTts } = InteractStore
  const { userPackage } = UserStore
  const [voiceGroups, setVoiceGroups] = useState<any[]>([])
  const [globalVoiceGroups, setGlobalVoiceGroups] = useState<any[]>([])
  const [detailGroup, setDetailGroup] = useState<any>()
  const [detailGroupVoices, setDetailGroupVoices] = useState<any[]>([])
  const [tabIndex, setTabIndex] = useState<number>()
  const [previewId, setPreviewId] = useState()
  const [playingId, setPlayingId] = useState()
  const audioRef = useRef<any>()
  const tabs = ['我的声音', '公共声音']
  const [cloneHighDrawerOpen, setCloneHighDrawerOpen] = useState(false)
  const timerRef = useRef<any>()

  const showGroups = useMemo(() => {
    if (tabIndex === 0) {
      return voiceGroups
    }

    if (tabIndex === 1) {
      return globalVoiceGroups
    }

    return []
  }, [voiceGroups, globalVoiceGroups, tabIndex])

  useEffect(() => {
    if (open) {
      getVoiceGroups()
      getGlobalVoiceGroups()
      ;(window as any).Buffer = Buffer
    } else {
      clearEvent()
    }
  }, [open])

  useEffect(() => {
    if (detailGroup?.group_id) {
      getDetailGroupVoices(true)
      loopDetailGroupVoices()
    } else {
      clearTimer()
      setDetailGroupVoices([])
    }
  }, [detailGroup])

  const clearTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current)
    }
  }

  const clearEvent = () => {
    setDetailGroup(undefined)
    setTabIndex(undefined)
    closeAudio()
    clearTimer()
  }

  const getVoiceGroups = async () => {
    const res = await homeApi.getSelfTtsGroups({ for_avachat: true })
    if (res?.list?.find((l: any) => l.id === selectTts.group_id)) {
      setTabIndex(0)
    }
    setVoiceGroups((res.list || []).filter((l: any) => l?.members?.length))
  }

  const getGlobalVoiceGroups = async () => {
    const res = await homeApi.getGlobalTtsGroups({ for_avachat: true })
    if (res?.list?.find((l: any) => l.id === selectTts.group_id)) {
      setTabIndex(1)
    }
    setGlobalVoiceGroups(res.list || [])
  }

  const getDetailGroupVoices = async (check?: boolean) => {
    const res = await homeApi.getVoicGroupDetail(detailGroup.group_id, { for_avachat: true })
    const list = (res?.list || []).filter((l: any) => l.status === 1)
    setDetailGroupVoices(res?.list || [])

    if (check && list?.length && (userPackage?.current_membership_level || 0) >= 20) {
      InteractStore.selectTts = {
        ...detailGroup,
        ...list[0]
      }
    }
  }

  const loopDetailGroupVoices = () => {
    if (tabIndex !== 0) return
    clearTimer()
    timerRef.current = setInterval(() => {
      getDetailGroupVoices()
    }, 3000)
  }

  const previewTts = async (tts: any, text: string) => {
    if (playingId === tts.id) {
      closeAudio()
      return
    } else if (previewId === tts.id) {
      return
    }

    setPreviewId(tts.id)
    setPlayingId(undefined)

    try {
      const res = await homeApi.previewTts(tts.id, { text, voice_parameters: tts.voice_parameters })
      setPlayingId(tts.id)

      if (audioRef.current) {
        audioRef.current.pause?.()
        audioRef.current.src = ''
      }

      const audio = new Audio()
      audio.src = `data:audio/wav;base64,${wavConverter
        .encodeWav(new Buffer(res.audio_base64, 'base64'), {
          numChannels: 1,
          sampleRate: 16000,
          byteRate: 32_000
        })
        .toString('base64')}`
      audio.play()
      audioRef.current = audio

      audio.addEventListener('ended', function () {
        closeAudio()
      })

      audio.addEventListener('pause', function () {
        closeAudio()
      })
    } catch (e) {
      closeAudio()
    }
  }

  const closeAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause?.()
      audioRef.current.src = ''
    }
    setPlayingId(undefined)
    setPreviewId(undefined)
  }

  const vipTip = () => {
    Modal.confirm({
      title: '当前会员等级不够，无法使用',
      content: <div>高保真声音是尊享版及以上会员专属功能，请您确认当前会员等级是否匹配</div>,
      okText: '升级会员',
      cancelText: '取消',
      onOk: () => {
        upgradePlan?.()
      }
    })
  }

  const openVipModal = () => {
    Modal.confirm({
      title: '会员专属',
      content: <div>精品声音是会员专属功能，成为会员后可以使用精品声音来创作作品</div>,
      okText: '开通会员',
      cancelText: '取消',
      onOk: () => {
        upgradePlan?.()
      }
    })
  }

  const voiceCloneTip = () => {
    Modal.confirm({
      title: '当前会员等级不够，无法使用',
      content: <div>声音克隆-高保真是尊享版及以上会员专属功能，请您确认当前会员等级是否匹配</div>,
      okText: '升级会员',
      cancelText: '取消',
      onOk: () => {
        upgradePlan?.()
      }
    })
  }

  const onBack = () => {
    setDetailGroup(undefined)
    closeAudio()
  }

  const changeVoice = (t: any) => {
    if (t.status !== 1) {
      return
    }
    if ((userPackage?.current_membership_level || 0) < 20 && t.level === 20) {
      return vipTip()
    }

    if ((userPackage?.current_membership_level || 0) < 10 && t.is_premium) {
      return openVipModal()
    }
    setDetailGroup(undefined)
    InteractStore.selectTts = {
      ...detailGroup,
      ...t
    }
    onCancel?.()
  }

  const onCloneVoice = () => {
    if ((userPackage?.current_membership_level || 0) < 20) {
      return voiceCloneTip()
    }
    setCloneHighDrawerOpen(true)
  }

  const onOk = () => {
    if (detailGroup?.id) {
      getDetailGroupVoices()
      loopDetailGroupVoices()
    } else {
      getVoiceGroups()
    }
  }

  return (
    <>
      <Modal className="tab-modal" onCancel={onCancel} footer={null} width={1020} open={open}>
        <div className="tab-modal-content tab-modal-voice-content">
          <div className="tabs">
            {tabs.map((t, index) => (
              <div
                className={`tabs-item ${tabIndex === index ? 'actived' : ''}`}
                key={t}
                onClick={() => {
                  setTabIndex(index)
                  setDetailGroup(undefined)
                }}
              >
                {t}
              </div>
            ))}
          </div>
          <div className="main">
            {tabIndex === 0 && (
              <div className="tip">
                <label className="red">*</label>目前仅高保真声音支持实时互动
              </div>
            )}
            {!detailGroup?.group_id ? (
              <>
                <div className="voice-group-list">
                  {tabIndex === 0 && (
                    <div className="voice-group-list__add" onClick={onCloneVoice}>
                      <PlusCircleFilled />
                      <div>声音克隆</div>
                    </div>
                  )}
                  {showGroups.map((g) => (
                    <div
                      className={`voice-group-list__item ${g.id === selectTts?.group_id ? 'actived' : ''}`}
                      key={g.id}
                      onClick={() => {
                        setDetailGroup({
                          ...g,
                          group_id: g.id,
                          group_title: g.title
                        })
                      }}
                    >
                      <div className="left">
                        <img src={g.cover_url} />
                      </div>
                      <div className="right">
                        <div className="name ellipsis">{g.title}</div>
                        <div className="style">{g?.members?.length || 0}种风格</div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="voice-detail">
                <div className="title">
                  <img src={ArrowLeft} onClick={onBack} className="arrow-left" />
                  <label>{detailGroup?.group_title}</label>
                </div>

                <div className="detail-list">
                  {tabIndex === 0 && (
                    <div className="detail-list__add" onClick={onCloneVoice}>
                      <PlusCircleFilled />
                      <div>添加更多风格</div>
                    </div>
                  )}
                  {detailGroupVoices.map((t) => (
                    <div
                      className={`detail-list__item ${selectTts.id === t.id ? 'actived' : ''}`}
                      key={t.id}
                      onClick={changeVoice.bind(this, t)}
                    >
                      <div
                        className="left"
                        onClick={(e) => {
                          e.stopPropagation()
                          if (userPackage && (userPackage.current_membership_level || 0) < 20 && t.level === 20) {
                            return vipTip()
                          }
                          previewTts(t, '现在的一切都是为将来的梦想编织翅膀，让梦想在现实中展翅高飞。')
                        }}
                      >
                        {previewId === t.id ? (
                          playingId === t.id ? (
                            <PauseOutlined />
                          ) : (
                            <LoadingOutlined />
                          )
                        ) : (
                          <Play className="play" />
                        )}
                      </div>
                      <div className="center">
                        <div className="name ellipsis">{t.display_name}</div>
                      </div>

                      <div className="right">
                        {t.is_premium && <img src={AudioTagGood} />}
                        {t.level === 20 && <img src={AudioTag} />}
                      </div>

                      {t.status === 2 && (
                        <div className="cloning">
                          <label>
                            克隆中
                            <LoadingOutlined />
                          </label>
                        </div>
                      )}

                      {t.status === 3 && (
                        <div className="error">
                          <label>克隆失败</label>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>

      <CloneHighDrawer
        open={cloneHighDrawerOpen}
        group={detailGroup}
        onOk={onOk}
        onCancel={() => setCloneHighDrawerOpen(false)}
      />
    </>
  )
}

export default memo(VoiceModal)

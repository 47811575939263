import './style.scss'
import { Button, Input, message, Modal, Radio } from 'antd'
import { FC, memo, useEffect, useState } from 'react'
import * as Api from '@/api/home'
import { PlusOutlined } from '@ant-design/icons'

interface IProps {
  avatarId?: number
  onSuccess?: () => void
  onCancel?: () => void
}

const Groups: FC<IProps> = (props) => {
  const { avatarId, onSuccess, onCancel } = props
  const [toGroupId, setToGroupId] = useState<number>()
  const [groups, setGroups] = useState<any[]>([])
  const [addGroupShow, setAddGroupShow] = useState(false)
  const [groupName, setGroupName] = useState('')

  useEffect(() => {
    if (avatarId) {
      getGroups()
    }
  }, [avatarId])

  const onSelectChange = (id: number) => {
    if (toGroupId !== id) {
      setToGroupId(id)
    } else {
      setToGroupId(undefined)
    }
  }

  const getGroups = async () => {
    const res = await Api.getDigitalHumanGroups()
    setGroups(res.list || [])
  }

  const showAddGroup = () => {
    setAddGroupShow(true)
  }

  const addGroup = async () => {
    const res = await Api.addDigitalGroup({
      title: groupName
    })
    getGroups()
    setToGroupId(res.id)
    setAddGroupShow(false)
    setGroupName('')
  }

  const moveGroup = async () => {
    await Api.updateDigitalHumans(avatarId as number, {
      group_id: toGroupId
    })

    message.success('移动成功')
    onSuccess?.()
    onCancel?.()
  }

  return (
    <>
      <Modal
        width={510}
        okButtonProps={{
          disabled: !toGroupId
        }}
        title={'移动到'}
        open={!!avatarId}
        okText="移动"
        className="commom-modal group-modal"
        onCancel={onCancel}
        onOk={moveGroup}
      >
        <div className="group-list">
          {groups.map((g) => (
            <div key={g.id} className="list-item" onClick={onSelectChange.bind(this, g.id)}>
              <Radio.Group value={toGroupId}>
                <Radio value={g.id}></Radio>
              </Radio.Group>
              {g.title}
            </div>
          ))}
          <Button className="btn-add" icon={<PlusOutlined />} onClick={showAddGroup}>
            新建
          </Button>
        </div>
      </Modal>

      <Modal
        title="新建 数字人"
        open={addGroupShow}
        width={400}
        className="commom-modal group-add-modal"
        onOk={addGroup}
        onCancel={() => setAddGroupShow(false)}
      >
        <div className="group-add-content">
          <Input value={groupName} placeholder="请输入数字人名称" onChange={(e) => setGroupName(e.target.value)} />
        </div>
      </Modal>
    </>
  )
}

export default memo(Groups)

import './style.scss'
import '../avatar-market/style.scss'
import { Checkbox, Popover } from 'antd'
import { FC, memo, useEffect, useMemo, useState } from 'react'
import * as Api from '@/api/home'
import { eventTracking } from '@/libs/util'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import MarketModal from './components/market-modal'

const CheckboxGroup = Checkbox.Group

const AvatarMarket: FC = () => {
  const [data, setData] = useState<any[]>([])
  const [marketModalOpen, setMarketModalOpen] = useState(false)
  const [marketDetail, setMarketDetail] = useState<any>()
  const [tags, setTags] = useState<string[]>(['全部'])
  const [tagList, setTagList] = useState<any[]>([])

  const showData = useMemo(() => {
    return data
  }, [data])

  useEffect(() => {
    eventTracking('DigitalStore')
    getTags()
  }, [])

  useEffect(() => {
    getData()
  }, [tags])

  const getData = async () => {
    const submitTags = tags
      .filter((t) => t !== '全部')
      ?.map((t) => {
        return `tags=${t}`
      })
    const [merchandises] = await Promise.all([
      Api.getMerchandisesV2(submitTags.length ? `?${submitTags.join('&')}` : '')
    ])
    setData(merchandises.list || [])
  }

  const getTags = async () => {
    const res = await Api.getMerchandiseTags()
    setTagList([
      {
        label: '全部',
        value: '全部'
      },
      ...(res.tags || []).map((t: any) => ({
        label: t,
        value: t
      }))
    ])
  }

  const onTagsChange = (values: any[]) => {
    if (tags.toString() === '全部' && !values.length) {
      return
    } else if ((!tags.includes('全部') && values.includes('全部')) || !values.length) {
      setTags(['全部'])
    } else if (tags.includes('全部')) {
      setTags(values.filter((v) => v !== '全部'))
    } else {
      setTags(values)
    }
  }

  const onMarketClick = (data: any) => {
    eventTracking('DigitalStoreClick', {
      id: data.id,
      name: data.name
    })
    setMarketDetail(data)
    setMarketModalOpen(true)
  }

  const onCancel = () => {
    getData()
    setMarketDetail(undefined)
    setMarketModalOpen(false)
  }

  return (
    <div className="page-common page-avatar-market">
      <div className="common-title">
        <div className="text">数字市场</div>
      </div>
      <div className="nav">
        <CheckboxGroup options={tagList} value={tags} onChange={onTagsChange} />
        <a
          target="_blank"
          className="link"
          href="https://lingverse.feishu.cn/share/base/form/shrcnNx9YWvGeYFc7DEF20CrAAW"
          rel="noreferrer"
        >
          个性化定制
        </a>
      </div>
      <div className="page-container">
        {showData.map((d) => (
          <div className="list-image-box" key={`${d.id}${d.name}`} onClick={onMarketClick.bind(this, d)}>
            <div className="box-main">
              <div className="image">
                <img src={d.covers?.[0]} className={`${d.name}`} />
              </div>
              {d.special_cost && (
                <Popover
                  content={
                    <div className="common-popover w400" onClick={(e) => e.stopPropagation()}>
                      <div>
                        <strong>创作优惠：</strong>
                      </div>
                      <ul>
                        <li>
                          标有“创作优惠”的数字演员。雇佣后，在创作视频时享受积分消耗优惠（{d.creation_cost_per_second}{' '}
                          积分/秒），即：
                          <div className="ml10">a. 使用音频驱动生成视频时：{d.creation_cost_per_second} 积分/秒；</div>
                          <div className="ml10">
                            b. 使用公版声音、声音克隆-基础版生成视频时：{d.creation_cost_per_second + 1} 积分/秒；
                          </div>
                          <div className="ml10">
                            c. 使用声音克隆-高保真生成视频时：{d.creation_cost_per_second + 2} 积分/秒；
                          </div>
                        </li>
                        <li>仅从数字市场雇佣才有“创作优惠”标记，其他方式上传的视频均无法享有；</li>
                      </ul>
                    </div>
                  }
                  placement="bottom"
                >
                  <div className="discount" onClick={(e) => e.stopPropagation()}>
                    创作优惠
                    <ExclamationCircleOutlined />
                  </div>
                </Popover>
              )}

              <div className="name">
                <span className="ellipsis">{d.name}</span> <span className="num">{d.videos?.length || 0}个形象</span>
              </div>
              <div className="suit ellipsis">适合场景：{d.scene}</div>
              <div className="bottom">
                <div className={`price ellipsis ${d.status === 2 ? 'gray' : ''}`}>
                  <label>{d.price || d.starting_price}</label>
                  <label className="credits">积分{d.type === 2 && d.status !== 2 ? '起' : ''}</label>
                </div>
                {d.status === 2 && <div className="tag end">已被雇佣</div>}
              </div>
            </div>
          </div>
        ))}
      </div>

      <MarketModal open={marketModalOpen} marketDetail={marketDetail} onCancel={onCancel} />
    </div>
  )
}

export default memo(AvatarMarket)

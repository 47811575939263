import './style.scss'
import { Button, Drawer, Input, message, Modal, notification, Popconfirm, Progress, Radio, Space } from 'antd'
import { RcFile } from 'antd/es/upload'
import Dragger from 'antd/es/upload/Dragger'
import axios from 'axios'
import { FC, memo, useEffect, useRef, useState } from 'react'
import { cmsApi } from '@/api'
import * as Api from '@/api/account'
import * as homeApi from '@/api/home'
import PayPointModal from '@/components/PayPointModal'
import { IUserPackage, UserStore } from '@/global-states'
import { eventTracking } from '@/libs/util'
import { CloudUploadOutlined, ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons'

interface IProps {
  open: boolean
  onCancel?: () => void
  onOk?: () => void
  group?: any
}

const CloneHighDrawer: FC<IProps> = (props) => {
  const { open, onCancel, onOk, group } = props
  const [name, setName] = useState('')
  const [audioUrl, setAudioUrl] = useState<string>(undefined as any)
  const [audioInfo, setAudioInfo] = useState<any>({})
  const [percent, setPercent] = useState(-1)
  const [loading, setLoading] = useState(false)
  const [declareChecked, setDeclareChecked] = useState(false)
  const cancelTokenSource = useRef<any>()
  const [previewLoading, setPreviewLoading] = useState(false)
  const [previeUrl, setPreviewUrl] = useState('')
  const audioRef = useRef<any>()
  const [clonePreflight, setClonePreflight] = useState<any>()
  const [payPointModalOpen, setPayPointModalOpen] = useState(false)
  const [showGuide, setShowGuide] = useState<any>(false)
  const [cloneableVoices, setCloneableVoices] = useState<any[]>([])
  const [cloneId, setCloneId] = useState<any>()
  const [restartCloneNum, setRestartCloneNum] = useState(0)
  const [cloneConfirmDisabled, setCloneConfirmDisabled] = useState(false)
  const [title, setTitle] = useState('')

  useEffect(() => {
    if (open) {
      getCloneableVoices()
      getClonePreflight()
      setPreviewLoading(false)
      setPreviewUrl('')
      setName('')
      resetUploadAudio()
      setTitle('')
    }
  }, [open])

  const getClonePreflight = async () => {
    const res = await Api.clonePreflight({
      level: 20
    })
    setClonePreflight(res || {})
  }

  const getCloneableVoices = async (refresh?: boolean) => {
    const res = await homeApi.getCloneableVoices()
    setCloneableVoices(res?.list || [])
    setCloneId(res?.list?.[0]?.id)
    setRestartCloneNum(res?.list?.[0]?.remained_demo_tries || 0)
    if (refresh) return
    if (res?.list?.[0]?.last_demo_source_audio) {
      setAudioUrl(res?.list?.[0]?.last_demo_source_audio)
      setPreviewUrl(res?.list?.[0]?.last_demo_result_audio)
      setPercent(100)
    } else {
      setShowGuide(true)
    }
  }

  const beforeAudioUpload = async (file: RcFile) => {
    return new Promise((resolve) => {
      const type = file.name.split(/\./)?.at(-1)?.toLocaleLowerCase() as string
      if (['wav', 'mp3', 'm4a'].includes(type)) {
        const size = file?.size || 0
        if (size / 1000 / 1000 > 20) {
          message.warning('音频大小不能大于 20MB')
          return resolve(false)
        }
        const audioElement = document.createElement('audio')
        audioElement.src = URL.createObjectURL(file)
        audioElement.addEventListener('loadedmetadata', () => {
          console.log('videoElement', audioElement.duration)
          if (audioElement.duration < 5 || audioElement.duration > 180) {
            message.warning('音频时长要求为5秒～3分钟')
            return resolve(false)
          }
          resolve(true)
        })
      } else {
        message.warning('请上传mp3、m4a、wav格式音频文件')
        resolve(false)
      }
    })
  }

  const onDrop = async (file: RcFile) => {
    const type = file.name.split(/\./)?.at(-1)?.toLocaleLowerCase() as string
    if (!['wav', 'mp3', 'm4a'].includes(type)) {
      message.warning('请上传mp3、m4a、wav格式音频文件')
    }
  }

  const uploadFile = async (file: RcFile) => {
    try {
      const segs = (file.name || '').split(/\./)
      const { upload_url, oss_key, content_type, public_url } =
        (await cmsApi.post('upload_url', {
          extension: segs[segs.length - 1],
          media_type: 2
        })) || {}
      if (!upload_url) {
        throw new Error('failed to upload file')
      }

      cancelTokenSource.current = axios.CancelToken.source()

      setPercent(0)

      await cmsApi.upload(upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''), file, {
        onUploadProgress: (progress) => {
          const percent = Math.round((progress.progress || 0) * 100)
          setPercent(percent)
        },
        headers: {
          'Content-Type': content_type
        },
        cancelToken: cancelTokenSource.current?.token
      })
      setAudioInfo({
        title: segs[0],
        oss_key,
        public_url
      })
    } catch (err: any) {
      if (err?.code !== 'ERR_CANCELED') {
        message.error(err?.message || err)
      }
    }
  }

  const resetUploadAudio = () => {
    setPercent(-1)
    setAudioUrl('')
    setAudioInfo({})
    setPreviewUrl('')
  }

  const cancelUpload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.current?.cancel('取消上传')
      resetUploadAudio()
    }
  }

  const previewDemoConfirm = async () => {
    if (!audioInfo.oss_key) {
      return message.warning('请上传参考音频')
    }

    if (!cloneableVoices?.length) {
      setCloneConfirmDisabled(true)
      const res: IUserPackage = await UserStore.updateUserPackage()
      const totalPoint = (res.membership_credits || 0) + (res.permanent_credits || 0) + (res.free_credits || 0)
      setCloneConfirmDisabled(false)
      Modal.confirm({
        title: '积分消耗确认',
        content: (
          <div>
            <div>购买1个高保真声音需要消耗{clonePreflight?.credits_cost || 0}积分，请确保你的账户中有足额积分。</div>
            <div>
              <strong>你的积分余额：{totalPoint}积分</strong>
            </div>
            <div>
              <strong>
                本次消耗积分：<label className="red">{clonePreflight?.credits_cost || 0}积分</label>
              </strong>
            </div>
          </div>
        ),
        okText: '确认购买',
        cancelText: '取消',
        onOk: () => {
          previewDemo()
        }
      })
    } else {
      previewDemo()
    }
  }

  const previewDemo = async () => {
    eventTracking('InstantClonePremiumPreviewClick', {
      ossKey: audioInfo.oss_key
    })
    setPreviewLoading(true)

    try {
      if (cloneId) {
        const res: any = await homeApi.startCloneVoices(cloneId, {
          audio_oss_key: audioInfo.oss_key
        })
        setPreviewUrl(res.demo_url)
        setRestartCloneNum(res.remained_demo_tries || 0)
      } else {
        const { id } = await homeApi.buyCloneVoices()
        setCloneId(id)
        const res: any = await homeApi.startCloneVoices(id, {
          audio_oss_key: audioInfo.oss_key
        })
        setPreviewUrl(res.demo_url)
        setRestartCloneNum(res.remained_demo_tries || 0)
      }
    } catch (error: any) {
      if (error.code === 1002) {
        setPayPointModalOpen(true)
      }
    } finally {
      setPreviewLoading(false)
    }
  }

  const nextStep = () => {
    setShowGuide(false)
    setDeclareChecked(false)
    showDeclare()
  }

  const restartClone = () => {
    Modal.confirm({
      title: '温馨提示',
      content: (
        <div>
          “重新克隆”可以上传新的音频进行克隆，您还有{restartCloneNum}次“重新克隆”的机会。
          <span className="red">机会用完后，您无法再重新克隆，只能使用最后一次的克隆结果来完成克隆。</span>
        </div>
      ),
      okText: '已确认，重新克隆',
      cancelText: '取消',
      onOk: () => {
        resetUploadAudio()
        getCloneableVoices(true)
      }
    })
  }

  const showDeclare = () => {
    let index = 3

    const instance = Modal.confirm({
      width: 600,
      title: '使用者承诺须知',
      content: (
        <>
          <div>
            本声明将帮助您更好的在【飞影数字人】平台（下称“本平台”）使用相关工具上传和管理您的作品。您若上传作品，即视为您已充分知悉并充分接受以下内容：
          </div>
          <ul className="declare-list">
            <li>您作为使用者在本平台上传、发布的作品，应具有独立、完整的知识产权，不得侵犯他人知识产权等任何权利。</li>
            <li>
              您在使用本平台及上传、发布作品时，应当自觉遵守国家法律、法规，遵守公共秩序，尊重社会公德、社会主义制度、国家利益、公民合法权益、道德风尚和信息真实性等要求。如有违反，一经本平台发现将根据违规程度采取包括但不限于删除、下架、禁止发布内容、封禁账号等处理方式。如造成恶劣影响或涉嫌违法犯罪的，本平台将有权向有关管理机关或公安机关提交相关内容，并配合进行调查。
            </li>
            <li>
              若您上传的作品及作品中的素材（包括但不限于创意、文本、肖像、音频、图片、视频等）侵犯了任何第三方权利，本平台均有权在收到相关侵权投诉后对该被投诉的作品或用户账号依据相应规则，采取包括但不限于
              <label className="red">下架、警告、封禁账号</label>等处理方式。
            </li>
            <li>
              请勿使用我们的服务克隆或生成任何侵犯版权、违反道德伦理、或违反中华人民共和国法律法规的内容。我们生成的所有内容均带有详细日志，自动/人工复审，以及
              可溯源的隐形视频/音频水印，
              <label className="red">若发现您违反了相关规则，我们保留终止您的服务并上报公安机关等机构的权利。</label>
            </li>
            <li>
              更多信息请参阅
              <a target="_blank" href="/eula.html">
                用户协议
              </a>
              、
              <a target="_blank" href="/privacy_agreement.html">
                隐私协议
              </a>
              。
            </li>
          </ul>
        </>
      ),

      okText: (
        <div>
          我已知晓，同意<label style={{ display: 'inline-block', width: 36 }}>（{index}s）</label>
        </div>
      ),
      cancelText: '取消',
      okButtonProps: {
        disabled: true
      },
      onOk: () => {
        setDeclareChecked(true)
      },
      onCancel: () => {
        setDeclareChecked(false)
        onCancel?.()
      }
    })

    const si = setInterval(() => {
      index = index - 1
      instance.update({
        okText: (
          <div>
            我已知晓，同意{index > 0 ? <label style={{ display: 'inline-block', width: 36 }}>（{index}s）</label> : ''}
          </div>
        )
      })
      if (index < 1) {
        instance.update({
          okButtonProps: {
            disabled: false
          }
        })
        clearInterval(si)
      }
    }, 1000)
  }

  const checkAvalid = () => {
    if (!name?.trim() && !group?.id) {
      message.warning('请输入声音名称')
      return false
    }

    if (group?.id && !title) {
      message.warning('请输入声音风格')
      return false
    }

    if (!declareChecked) {
      message.warning('请阅读并同意《使用者承诺须知》')
      return false
    }
    return true
  }

  const onConfirm = async () => {
    if (!checkAvalid()) return

    eventTracking('InstantClonePremiumConfirm')
    Modal.confirm({
      title: '温馨提示',
      content: (
        <div>
          请先预览试听声音效果，如果对声音效果不满意，您可重新上传新的音频进行克隆（共2次重新克隆的机会）。建议您对声音效果满意后，再完成克隆，
          <span className="red">一旦完成克隆便无法再修改声音。</span>
        </div>
      ),
      okText: '已确认，完成克隆',
      cancelText: '确认效果',
      onCancel: () => {
        eventTracking('InstantClonePremiumCheck')
        audioRef.current.play()
      },
      onOk: async () => {
        eventTracking('InstantClonePremiumConfirmed')
        setLoading(true)
        try {
          const params: any = {
            with_group: {
              new_group_title: name
            }
          }

          if (group?.id) {
            params.voice_name = title
            params.with_group = {
              group_id: group.id
            }
          }
          await homeApi.completeCloneVoices(cloneId, params)
          notification.success({
            message: '声音克隆完成',
            description: '快使用你的声音去创作视频吧！'
          })
          onCancel?.()
          onOk?.()
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return (
    <>
      <Drawer
        className="create-high-drawer"
        title="声音克隆-高保真"
        width={720}
        onClose={onCancel}
        open={open}
        footer={
          <>
            <div className="declare">
              {!showGuide && (
                <>
                  <Radio checked={declareChecked} onClick={() => setDeclareChecked(!declareChecked)}></Radio>
                  我已阅读并同意
                  <label className="link" onClick={showDeclare}>
                    《使用者承诺须知》
                  </label>
                </>
              )}
            </div>
            <Space>
              {!showGuide ? (
                <>
                  {previeUrl ? (
                    <>
                      <div className="fee">
                        <div className="num gray">剩余修改次数：{restartCloneNum}</div>
                      </div>
                      <Button onClick={restartClone} disabled={restartCloneNum < 1}>
                        重新克隆
                      </Button>
                      <Button disabled={!declareChecked} type="primary" onClick={onConfirm} loading={loading}>
                        完成克隆
                      </Button>
                    </>
                  ) : (
                    <>
                      <div className="fee">
                        <div className="score">
                          <label className={`light ${cloneableVoices?.length > 0 ? 'through' : ''}`}>
                            消耗{clonePreflight?.credits_cost || 0}积分
                          </label>
                          {clonePreflight?.limited_time_free && <label className="light">限时免费</label>}
                        </div>
                        <div className={`num ${cloneableVoices?.length > 0 ? 'light' : 'gray'}`}>
                          剩余免费次数：{cloneableVoices?.length || 0}
                        </div>
                      </div>
                      <Button
                        type="primary"
                        onClick={previewDemoConfirm}
                        loading={previewLoading || cloneConfirmDisabled}
                      >
                        开始克隆
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <Button type="primary" onClick={nextStep}>
                  下一步
                </Button>
              )}
            </Space>
          </>
        }
      >
        <div className="main">
          {showGuide ? (
            <div className="form-item">
              <div className="title">声音克隆流程</div>
              <div className="desc-list">
                <div className="list-item">
                  <div className="list-title">
                    <strong>1. 上传参考音频</strong>
                  </div>
                  <div className="list-content">
                    <div>
                      上传一段音频用于克隆声音。音频的质量会直接影响到最后克隆声音的效果，因此请您尽量保证用于克隆的音频符合以下要求：
                    </div>
                    <div>
                      （1）<strong>音频时长：</strong>建议音频时长限制在10s-30s；
                    </div>
                    <div>
                      （2）<strong>音频质量：</strong>
                    </div>
                    <ul>
                      <li>a. 只有一个在说话，避免音频中出现多个人的声音；</li>
                      <li>b. 保证低底噪。说话人说话越清晰，最终克隆效果越好，音频底噪会严重影响克隆效果；</li>
                      <li>c. 保持音量大小、语速稳定、注意断句、避免口腔噪音(如口水声)、杂音、混响等情况；</li>
                      <li>d. 音频中不要有背景音乐；</li>
                      <li>e. 音频中不要有桌椅响声、键盘鼠标敲击声、衣服摩擦声等人为噪声；</li>
                      <li>f. 音频中可以存在口误。口误时无需终止录音,可停顿1~2秒后,继续录制即可。</li>
                    </ul>
                    <div>
                      （3）<strong>音频内容：</strong>
                    </div>
                    <ul>
                      <li>
                        在录制音频前建议先确定好声音风格，在录音时尽量去贴近风格，避免录制的音频情绪韵律趋于平淡。
                        <span className="red">
                          如果希望克隆出的声音情绪饱满、韵律有起伏，请尽量上传表现力强的音频来克隆。
                        </span>
                      </li>
                    </ul>
                    <div>
                      （4）<strong>音频格式：</strong>
                    </div>
                    <ul>
                      <li>支持mp3、m4a、wav文件格式，音频文件大小不超过20M。</li>
                    </ul>
                  </div>
                </div>
                <div className="list-item">
                  <div className="list-title">
                    <strong>2. 预览效果</strong>
                  </div>
                  <div className="list-content">
                    <div>音频上传后，请先预览试听声音效果。</div>
                    <div>
                      如果对声音效果不满意，您可重新上传新的音频进行效果试听，每个声音共有<strong>2次</strong>
                      修改编辑的机会。
                    </div>
                    <div>建议您保证音频符合上述要求，避免浪费修改编辑的次数。</div>
                  </div>
                </div>
                <div className="list-item">
                  <div className="list-title">
                    <strong>3. 积分消耗</strong>
                  </div>
                  <div className="list-content">
                    <div>
                      声音克隆-高保真 积分消耗： <strong>10000积分 / 个</strong>
                    </div>
                    <div>如果您的免费次数为0，我们会扣除10000积分，用于购买声音。</div>
                    <div>一经购买，概不退货。</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="form-item">
                <div className="title">声音名称</div>
                <Input
                  maxLength={20}
                  placeholder="请输入声音名称"
                  value={group?.title || name}
                  onChange={(e) => setName(e.target.value)}
                  disabled={group?.id}
                />
              </div>

              {group?.id && (
                <div className="form-item">
                  <div className="title">风格名称</div>
                  <Input
                    maxLength={20}
                    placeholder="请输入风格名称，如高兴、沮丧等"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              )}
              <div className="form-item">
                <div className="title">
                  参考音频
                  <Popconfirm
                    title="温馨提示"
                    description={
                      <div className="popconfirm-list" style={{ width: 620 }}>
                        <div>
                          （1）<strong>音频时长：</strong>建议音频时长限制在10s-30s；
                        </div>
                        <div>
                          （2）<strong>音频质量：</strong>
                        </div>
                        <ul>
                          <li>a. 只有一个在说话，避免音频中出现多个人的声音；</li>
                          <li>b. 保证低底噪。说话人说话越清晰，最终克隆效果越好，音频底噪会严重影响克隆效果；</li>
                          <li>c. 保持音量大小、语速稳定、注意断句、避免口腔噪音(如口水声)、杂音、混响等情况；</li>
                          <li>d. 音频中不要有背景音乐；</li>
                          <li>e. 音频中不要有桌椅响声、键盘鼠标敲击声、衣服摩擦声等人为噪声；</li>
                          <li>f. 音频中可以存在口误。口误时无需终止录音,可停顿1~2秒后,继续录制即可。</li>
                        </ul>
                        <div>
                          （3）<strong>音频内容：</strong>
                        </div>
                        <ul>
                          <li>
                            在录制音频前建议先确定好声音风格，在录音时尽量去贴近风格，避免录制的音频情绪韵律趋于平淡。
                            <span className="red">
                              如果希望克隆出的声音情绪饱满、韵律有起伏，请尽量上传表现力强的音频来克隆。
                            </span>
                          </li>
                        </ul>
                        <div>
                          （4）<strong>音频格式：</strong>
                        </div>
                        <ul>
                          <li>支持mp3、m4a、wav文件格式，音频文件大小不超过20M。</li>
                        </ul>
                      </div>
                    }
                    okText="我知道了"
                    showCancel={false}
                  >
                    <ExclamationCircleFilled />
                  </Popconfirm>
                </div>

                {!previeUrl && !previewLoading && (
                  <div className="warning">
                    <h4>音频要求</h4>
                    <div className="desc">
                      <div>
                        <label className="label">文件格式：</label>
                        <label>mp3、m4a、wav</label>
                      </div>
                      <div>
                        <label className="label">音频时长：</label>
                        <label>15秒～2分钟</label>
                      </div>
                      <div>
                        <label className="label">音频大小：</label>
                        <label>小于20MB</label>
                      </div>
                    </div>
                  </div>
                )}

                {percent === -1 && (
                  <Dragger
                    accept=".mp3,.m4a,.wav"
                    showUploadList={false}
                    beforeUpload={async (file) => {
                      const flag = (await beforeAudioUpload(file)) as any
                      if (flag) {
                        setAudioUrl(URL.createObjectURL(file))
                        uploadFile(file)
                      }
                      return flag
                    }}
                    onDrop={(e) => onDrop(e.dataTransfer.files?.[0] as any)}
                  >
                    <p className="ant-upload-drag-icon">
                      <CloudUploadOutlined />
                    </p>
                    <p className="ant-upload-text">请上传一段音频，作为制作数字人声音使用</p>
                    <p className="ant-upload-hint">将文件拖到此处，或点击此区域上传</p>
                  </Dragger>
                )}

                {percent > -1 && percent < 100 && (
                  <div className="step-progress">
                    <div className="step-progress-content">
                      <div className="percent">{percent}%</div>
                      <Progress percent={percent} showInfo={false} />
                      <div className="tips">音频上传中</div>
                    </div>
                    <div className="btns">
                      <Button onClick={cancelUpload}>取消上传</Button>
                    </div>
                  </div>
                )}

                {percent === 100 && (
                  <div className="step-view">
                    <div className="step-view-box">
                      <audio controls src={audioUrl} />
                    </div>
                    {!previeUrl && !previewLoading && (
                      <div className="btns">
                        <div
                          onClick={() => {
                            eventTracking('ReuploadClick')
                            resetUploadAudio()
                          }}
                        >
                          重新上传
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {previewLoading && (
                  <div className="step-preview">
                    <div className="preview-title">
                      效果预览
                      <Popconfirm
                        title="温馨提示"
                        description={
                          <div style={{ width: 350 }}>
                            如果克隆效果不好, 您可重新上传音频进行克隆. 建议您对声音克隆效果满意后再确认提交,
                            一旦提交便无法再修改声音.
                          </div>
                        }
                        okText="我知道了"
                        showCancel={false}
                      >
                        <ExclamationCircleFilled />
                      </Popconfirm>
                    </div>
                    <div className="preview-box">
                      <LoadingOutlined />
                      <div>预览结果生成中，请稍后</div>
                    </div>
                  </div>
                )}

                {previeUrl && (
                  <div className="step-view">
                    <div className="step-view-title">
                      效果预览
                      <Popconfirm
                        title="温馨提示"
                        description={
                          <div style={{ width: 350 }}>
                            如果克隆效果不好, 您可重新上传音频进行克隆. 建议您对声音克隆效果满意后再确认提交,
                            一旦提交便无法再修改声音.
                          </div>
                        }
                        okText="我知道了"
                        showCancel={false}
                      >
                        <ExclamationCircleFilled />
                      </Popconfirm>
                    </div>
                    <div className="step-view-box">
                      <audio ref={audioRef} controls src={previeUrl} />
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </Drawer>
      <PayPointModal open={payPointModalOpen} onCancel={() => setPayPointModalOpen(false)} />
    </>
  )
}

export default memo(CloneHighDrawer)

import './style.scss'
import { Button, Input, message, Modal, notification, Popover, Progress, Radio } from 'antd'
import { RcFile } from 'antd/es/upload'
import Dragger from 'antd/es/upload/Dragger'
import axios from 'axios'
import { Buffer } from 'buffer'
import { match, pinyin } from 'pinyin-pro'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as uuid from 'uuid'
import wavConverter from 'wav-converter'
import { cdnApi, cmsApi } from '@/api'
import * as homeApi from '@/api/home'
import ArrowLeft from '@/assets/arrow-left.png'
import Empty from '@/assets/empty.png'
import { PlayGray } from '@/assets/svg/play-gray'
import AudioDemos from '@/components/AudioDemos'
import PayPointModal from '@/components/PayPointModal'
import PlanModal from '@/components/PlanModal'
import VoiceSettingModal from '@/components/VoiceSettingModal'
import { UserStore } from '@/global-states'
import { eventTracking, urlSource } from '@/libs/util'
import {
  ArrowLeftOutlined,
  CheckOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FormOutlined,
  LoadingOutlined,
  PauseOutlined
} from '@ant-design/icons'
import CreateAvatarDrawer from '../home/components/CreatAvatarDrawer'
import RichText from './components/RichText'
import VoiceModal from './components/VoiceModal'

const CreateVideo: FC = () => {
  const navigate = useNavigate()
  const params: any = useParams()
  const { userPackage } = UserStore
  const [currentStep, setCurrentStep] = useState(0)
  const [audioFile, setAudioFile] = useState<RcFile>(undefined as any)
  const [audioUrl, setAudioUrl] = useState<string>(undefined as any)
  const [audioInfo, setAudioInfo] = useState<any>({})
  const [percent, setPercent] = useState(0)
  const cancelTokenSource = useRef<any>()
  const [selectTts, setSelectTts] = useState<any>() // 已经确认选择的发音
  const [selectTtsDetail, setSelectTtsDetail] = useState<any>() // 已经确认选择的发音
  const [data, setData] = useState<any[]>([])
  const [globalData, setGlobalData] = useState<any[]>([])
  const [avatarId, setAvatarId] = useState<any>(undefined)
  const [model, setModel] = useState('text')
  const [words, setWords] = useState('') // 文本驱动内容
  const [textInputName, setTextInputName] = useState('未命名') // 文本驱动作品名称
  const [previewId, setPreviewId] = useState<any>(undefined)
  const prviewRef = useRef<any>()
  const [loading, setLoading] = useState(false)
  const audioRef = useRef<any>()
  const currentRef = useRef<any>()
  const [planModalOpen, setPlanModalOpen] = useState(false)
  const [payPointModalOpen, setPayPointModalOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [settingPreview, setSettingPreview] = useState<any>()
  const [highSetting, setHighSetting] = useState<any>({
    drive_mode: 0,
    only_generate_audio: false,
    mode: -1 // 0-普通模式 1-暗黑模式
  })
  const [avatarTab, setAvatarTab] = useState<number>(undefined as any)
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [groups, setGroups] = useState<any[]>([])
  const [globalGroups, setGlobalGroups] = useState<any[]>([])
  const [group, setGroup] = useState<any>()
  const [groupId, setGroupId] = useState<any>()
  const [groupDigitals, setGroupDigitals] = useState<any[]>()
  const [sensitiveWords, setSensitiveWords] = useState<any[]>([])
  const [sensitiveWordsPinyin, setSensitiveWordsPinyin] = useState<any[]>([])
  const [voiceModalOpen, setVoiceModalOpen] = useState(false)
  const [playingId, setPlayingId] = useState()

  const maxLength = useMemo(() => {
    if (highSetting.mode === 1) {
      return 1500
    }
    if (highSetting.mode === 0) {
      return 10000
    }
  }, [highSetting.mode])

  useEffect(() => {
    ;(window as any).Buffer = Buffer
    setTextInputName('未命名')
    setWords(
      '从当下这一刻起，拒绝内耗。只因命运不会偏袒任何人，却会眷顾一直朝着光亮前进的人。我相信，路虽远，行则可至；事虽难，做则可成。一切好事将至，祝大家如愿以偿。'
    )
    restartUpload()
    getData()
    initData()
    getGroups()
    getGlobalGroups()
    initSensitiveWords()
    if (params.avatarId) {
      getAvatarInfo(+params.avatarId)
    }

    return () => {
      prviewRef.current = undefined
      closeAudio()
    }
  }, [])

  useEffect(() => {
    if (selectTts?.id) {
      localStorage.setItem('voiceId', selectTts.id)
      getSelectTtsDetail()
    }
  }, [selectTts])

  useEffect(() => {
    if (params.avatarId) {
      setAvatarId(+params.avatarId)
    }
  }, [params])

  useEffect(() => {
    if (data.some((d) => d.id === avatarId)) {
      setAvatarTab(0)
    }
    if (globalData.some((d) => d.id === avatarId)) {
      setAvatarTab(1)
    }
  }, [avatarId, data, globalData])

  useEffect(() => {
    if (audioFile) {
      setCurrentStep(1)
      setAudioUrl(URL.createObjectURL(audioFile))
    } else {
      setAudioUrl(undefined as any)
    }
  }, [audioFile])

  useEffect(() => {
    prviewRef.current = previewId
  }, [previewId])

  useEffect(() => {
    if (userPackage && highSetting.mode === -1) {
      if (userPackage?.current_membership_level === 50) {
        setHighSetting({ ...highSetting, mode: 1 })
      } else {
        setHighSetting({ ...highSetting, mode: 0 })
      }
    }
  }, [userPackage])

  useEffect(() => {
    if (group?.id) {
      getGroupDigitals()
    }
  }, [group])

  const saveName = () => {
    if (!textInputName?.trim()) {
      setTextInputName('未命名')
    }
    setEdit(false)
  }

  const initData = async () => {
    const [account, global, self] = await Promise.all([UserStore.updateUserPackage(), getGlobalTts(), getSelfTts()])
    const voiceId = localStorage.getItem('voiceId') || ''
    const speakers = [...self, ...global]?.filter((i) => i.status === 1) || []

    const level = account?.current_membership_level || 0

    // 过滤掉不可用的精品声音和高保真
    const canUseSpeakers = speakers.filter((s) => {
      if (s.is_premium && level < 10) {
        return false
      }
      if (s.level === 20 && level < 20) {
        return false
      }
      return true
    })

    const findVoice = canUseSpeakers.find((s) => s.id === +voiceId)
    let voice: any
    if (voiceId && findVoice) {
      voice = findVoice
    } else {
      voice = canUseSpeakers[0]
    }
    setSelectTts(voice)
  }

  const getGlobalTts = async () => {
    const res = await homeApi.getGlobalTts()
    setTimeout(() => {
      currentRef.current?.scrollIntoView()
    })
    return res.list || []
  }

  const getSelfTts = async () => {
    const res = await homeApi.getSelfTts()
    const list = (res.list || []).filter((r: any) => r.status === 1)
    if (selectTts?.id) {
      setSelectTts(list.find((l: any) => l.id === selectTts?.id))
    }
    return res.list || []
  }

  const getSelectTtsDetail = async () => {
    const res = await homeApi.getVoiceDetail(selectTts.id)
    setSelectTtsDetail({
      ...res,
      ...selectTts
    })
  }

  const updateData = async () => {
    if (group?.id) {
      const res = await homeApi.getDigitalHumansByGroup(group?.id as number)
      setGroupDigitals((res.list || [])?.filter((l: any) => l.status === 2))
      setAvatarId(res.list[0]?.id)
      getAvatarInfo(res.list[0]?.id)
    } else {
      const [humans] = await Promise.all([homeApi.getDigitalHumans()])
      setData(humans.list || [])
      setAvatarId(humans.list[0]?.id)
      getAvatarInfo(humans.list[0]?.id)
    }
    getGroups()
  }

  const getData = async () => {
    const [humans, globalHumans] = await Promise.all([homeApi.getDigitalHumans(), homeApi.getGlobalDigitalHumans()])
    const list = (humans.list || []).filter((d: any) => d.status === 2)
    setData(humans.list || [])
    setGlobalData(globalHumans.list || [])

    if (!params.avatarId) {
      let id
      if (list?.length) {
        id = list[0]?.id
      } else {
        id = globalHumans.list?.[0]?.id
      }
      setAvatarId(id)
      getAvatarInfo(id)
    }
  }

  const getGroups = async () => {
    const res = await homeApi.getDigitalHumanGroups()
    setGroups(res.list || [])
  }

  const getGlobalGroups = async () => {
    const res = await homeApi.getGlobalDigitalHumanGroups()
    setGlobalGroups(res.list || [])
  }

  const getAvatarInfo = async (avatarId: number) => {
    const res = await homeApi.getDigitalInfo(avatarId)
    setGroupId(res.group_id)
    setGroup({
      id: res.group_id,
      title: res.group_title,
      member_count: res.group_member_count
    })
  }

  const getGroupDigitals = async () => {
    const res = await homeApi.getDigitalHumansByGroup(group?.id as number)
    const list = (res.list || [])?.filter((l: any) => l.status === 2)
    setGroupDigitals(list)
    if (list?.length) {
      setAvatarId(list[0].id)
      setGroupId(group?.id as number)
    }
    setTimeout(() => {
      currentRef.current?.scrollIntoView()
    })
  }

  const initSensitiveWords = async () => {
    const [content, pinyinContent] = await Promise.all([
      cdnApi.get(`hiflys/sensitive_words_lines.json?date=${Date.now()}`),
      cdnApi.get(`hiflys/sensitive_words_pinyin.json?date=${Date.now()}`)
    ])
    setSensitiveWords(content || [])
    setSensitiveWordsPinyin(pinyinContent || [])
  }

  const checkSensitive = () => {
    const errorWords: any[] = []
    const platWords = translateHtml(words).replace(/[^\u4e00-\u9fa5a-zA-Z0-9]/g, '')
    const pinyinWords = pinyin(platWords, { type: 'array', toneType: 'none' })
    const pinyinWordsString = pinyinWords?.join('')

    try {
      // 精确匹配
      sensitiveWords?.forEach((word) => {
        if (word?.length && !errorWords.includes(word) && platWords.includes(word)) {
          errorWords.push(word)
        }
      })

      // 拼音校验
      sensitiveWordsPinyin?.forEach((word) => {
        if (word?.length && pinyinWordsString.includes(word)) {
          const matchs = match(platWords, word, { continuous: true, precision: 'every' })
          if (matchs?.length) {
            const matchsString = platWords.substring(matchs[0], (matchs.at(-1) || 0) + 1)
            !errorWords.includes(matchsString) && errorWords.push(matchsString)
          }
        }
      })
    } catch (err) {
      console.log(err)
    }

    return errorWords
  }

  const uploadFile = async (file: RcFile) => {
    try {
      const segs = (file.name || '').split(/\./)
      const { upload_url, oss_key, content_type } =
        (await cmsApi.post('upload_url', {
          extension: segs[segs.length - 1],
          media_type: 2
        })) || {}
      if (!upload_url) {
        throw new Error('failed to upload file')
      }

      cancelTokenSource.current = axios.CancelToken.source()
      setPercent(0)
      setCurrentStep(1)

      await cmsApi.upload(upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''), file, {
        onUploadProgress: (progress) => {
          const percent = Math.round((progress.progress || 0) * 100)
          setPercent(percent)
        },
        headers: {
          'Content-Type': content_type
        },
        cancelToken: cancelTokenSource.current?.token
      })
      setAudioInfo({
        title: segs[0],
        oss_key
      })
      setCurrentStep(2)
    } catch (err: any) {
      if (err?.code !== 'ERR_CANCELED') {
        message.error(err?.message || err)
      }
    }
  }

  const beforeAudioUpload = async (file: RcFile) => {
    return new Promise((resolve) => {
      const type = file.name.split(/\./)?.at(-1)?.toLocaleLowerCase() as string
      if (['wav', 'mp3', 'm4a'].includes(type)) {
        const audioElement = document.createElement('audio')
        audioElement.src = URL.createObjectURL(file)
        audioElement.addEventListener('loadedmetadata', () => {
          console.log('videoElement', audioElement.duration)
          if (audioElement.duration < 5 || audioElement.duration > 1800) {
            message.warning('音频时长要求为5秒～30分钟')
            resolve(false)
          }
          resolve(true)
        })
      } else {
        message.warning('请上传mp3、m4a、wav格式音频文件')
        resolve(false)
      }
    })
  }

  const onDrop = async (file: RcFile) => {
    const type = file.name.split(/\./)?.at(-1)?.toLocaleLowerCase() as string
    if (!['wav', 'mp3', 'm4a'].includes(type)) {
      message.warning('请上传mp4、mov格式视频文件')
    }
  }

  const restartUpload = () => {
    cancelUpload()
    setCurrentStep(0)
    setPercent(0)
    setAudioFile(undefined as any)
    setAudioInfo(undefined as any)
  }

  const cancelUpload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.current?.cancel('取消上传')
    }
  }

  const translateHtml = (text: string) => {
    if (text) {
      return text
        .replace(/<(?!\/?(break|phoneme|category)\b)[^>]+>/g, '')
        .replace(/<break\s+class="[^"]*"\s+time="([^"]*)"\s+time-text="[^"]*"\s*>/g, '<break time="$1">')
        .replace(/&nbsp;/g, ' ')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&amp;/g, '&')
        .replace(/&nbsp;/g, "'")
        .replace(/&quot;/g, '"')
        .replace(/<(?!break\b[^>]*>)(?!\/break>)/g, '')
        .replace(/(<\/?break\b[^>]*>)|>/g, (match: any, p: any) => {
          // 如果匹配的是 <break> 或 </break>，则保留它
          if (p) return p
          // 否则，移除 '>'
          return ''
        })
    }
    return text
  }

  const completeCreate = async () => {
    setLoading(true)
    const errorWords = checkSensitive()
    if (errorWords?.length) {
      setLoading(false)
      eventTracking('sensitiveWords', {
        sensitiveWord: errorWords.join('、'),
        text: translateHtml(words).replace(/[^\u4e00-\u9fa5a-zA-Z0-9]/g, '')
      })
      return message.error(
        <div style={{ padding: '12px' }}>
          您提交的输入描述词包含敏感字符<label className="red">（{errorWords.join('、')}</label>），请修改后再提交
        </div>
      )
    }

    try {
      const res = await homeApi.authRotate({
        seed: uuid.v4(),
        replace: true
      })

      const checkRes = await UserStore.checkUserPackage()

      if (checkRes) return

      let postRes

      if (model === 'text') {
        eventTracking('CreateConfirm', {
          avatarId,
          voiceName: selectTts.name
        })
        const translateWords = translateHtml(words)
        postRes = await homeApi.addCreations(res.server_time, {
          type: 0,
          digital_human_id: avatarId,
          title: textInputName,
          text_to_audio: {
            text: translateWords,
            voice_name: selectTts.name
          },
          client_type: 1,
          drive_mode: highSetting.drive_mode,
          only_generate_audio: highSetting.only_generate_audio,
          mode: highSetting.mode
        })
      } else {
        eventTracking('CreateConfirm', {
          avatarId,
          ossKey: audioInfo.oss_key
        })
        postRes = await homeApi.addCreations(res.server_time, {
          type: 0,
          digital_human_id: avatarId,
          title: textInputName,
          audio_oss_key: audioInfo.oss_key,
          client_type: 1,
          drive_mode: highSetting.drive_mode,
          mode: highSetting.mode
        })
      }

      if (postRes.sensitive_words) {
        return Modal.error({
          title: '提交失败',
          content: (
            <div>
              您提交的内容中包含敏感内容（<span className="red">{postRes.sensitive_words.join('、')}</span>
              ），请修改后再次提交。
            </div>
          )
        })
      }

      if (model === 'text' && highSetting.only_generate_audio) {
        notification.success({
          message: '音频生成中',
          description: '音频生成中...，可在作品管理中查看！'
        })
      } else {
        notification.success({
          message: '视频生成中',
          description: '新的数字人驱动视频生成中...，可在作品管理中查看！'
        })
      }

      toVideo()
    } catch (error: any) {
      if (error.code === 1002) {
        setPayPointModalOpen(true)
      }
      if (error.code === 1003 || error.code === 1004 || error.code === 1005) {
        setPlanModalOpen(true)
      }
    } finally {
      setLoading(false)
    }
  }

  const submitText = () => {
    const text = words.replace(/<[^>]*>/g, '')

    if (!words.trim()) {
      return message.warning('请输入台词')
    }

    if (text.trim()?.length < 5) {
      return message.warning('台词至少五个字')
    }
    completeCreate()
  }

  const submitAuduio = () => {
    if (!audioInfo?.oss_key) {
      return message.warning('请上传音频')
    }
    completeCreate()
  }

  const onChange = (text: string) => {
    setWords(text)
  }

  const previewWords = async (words: string) => {
    return await previewTts(selectTts, words)
  }

  const previewTts = async (tts: any, text: string) => {
    if (playingId === tts.id) {
      closeAudio()
      return
    } else if (previewId === tts.id) {
      return
    }

    setPreviewId(tts.id)
    setPlayingId(undefined)

    try {
      const res = await homeApi.previewTts(tts.id, { text, voice_parameters: tts.voice_parameters })
      setPlayingId(tts.id)

      if (audioRef.current) {
        audioRef.current.pause?.()
        audioRef.current.src = ''
      }

      const audio = new Audio()
      audio.src = `data:audio/wav;base64,${wavConverter
        .encodeWav(new Buffer(res.audio_base64, 'base64'), {
          numChannels: 1,
          sampleRate: 16000,
          byteRate: 32_000
        })
        .toString('base64')}`
      audio.play()
      audioRef.current = audio

      audio.addEventListener('ended', function () {
        closeAudio()
      })

      audio.addEventListener('pause', function () {
        closeAudio()
      })
    } catch {
      closeAudio()
    }
  }

  const closeAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause?.()
      audioRef.current.src = ''
    }
    setPlayingId(undefined)
    setPreviewId(undefined)
  }

  const toVideo = () => {
    location.href = '/video'
  }

  const onDriveModelChange = (e: any) => {
    const value = e.target.value
    if (userPackage && (userPackage.current_membership_level || 0) < 20 && value === 1) {
      return Modal.confirm({
        title: '当前会员等级不够，无法使用',
        content: <div>驱动模式-顺序驱动是尊享版及以上会员专属功能，请您确认当前会员等级是否匹配</div>,
        okText: '升级会员',
        cancelText: '取消',
        onOk: () => {
          setPlanModalOpen(true)
        }
      })
    }
    setHighSetting({
      ...highSetting,
      drive_mode: e.target.value
    })
  }

  const onAudioModelChange = (e: any) => {
    const value = e.target.value
    if (highSetting.mode === 1 && value) {
      return message.warning('暗黑模式下不支持仅音频输出')
    }
    if (userPackage && (userPackage.current_membership_level || 0) < 20 && value) {
      return Modal.confirm({
        title: '当前会员等级不够，无法使用',
        content: <div>输出设置-仅音频是尊享版及以上会员专属功能，请您确认当前会员等级是否匹配</div>,
        okText: '升级会员',
        cancelText: '取消',
        onOk: () => {
          setPlanModalOpen(true)
        }
      })
    }
    setHighSetting({
      ...highSetting,
      only_generate_audio: value
    })
  }

  const onModeChange = (e: any) => {
    const value = e.target.value
    if (highSetting.only_generate_audio && value === 1) {
      return message.warning('暗黑模式下不支持仅音频输出')
    }
    if (userPackage && (userPackage.current_membership_level || 0) !== 50 && value === 1) {
      return Modal.confirm({
        title: '黑金会员专属功能',
        content: <div>暗黑模式是黑金会员专属功能，请您确认当前会员等级是否匹配</div>,
        okText: '开通黑金会员',
        cancelText: '取消',
        onOk: () => {
          setPlanModalOpen(true)
        }
      })
    }
    setHighSetting({
      ...highSetting,
      mode: value
    })
  }

  const selectDemoAudio = (v: any) => {
    setAudioInfo({
      oss_key: v.ossKey
    })
    setAudioUrl(urlSource(v.url))
    setCurrentStep(2)
  }

  return (
    <div className="page-create page-create-video">
      <div className="page-header">
        <div className="title">
          <span className="icon" onClick={() => navigate(-1)}>
            <ArrowLeftOutlined />
          </span>
          <label>创建视频</label>
        </div>
        <div className="right">
          {edit ? (
            <Input
              autoFocus
              maxLength={20}
              value={textInputName}
              onChange={(e) => setTextInputName(e.target.value)}
              onBlur={saveName}
              onPressEnter={saveName}
            ></Input>
          ) : (
            <label className="name">
              {textInputName}
              <FormOutlined onClick={() => setEdit(true)} />
            </label>
          )}
        </div>
      </div>

      <div className="create-content">
        <div className="left">
          {group && (
            <>
              {group?.id ? (
                <>
                  <div className="left-title">
                    <img
                      src={ArrowLeft}
                      onClick={() => {
                        setGroup({})
                        setGroupDigitals(undefined)
                      }}
                    />

                    {group.title}
                  </div>
                  <div className="avatar">
                    {groupDigitals && (
                      <>
                        {groupDigitals?.length ? (
                          <div className="list">
                            {groupDigitals.map((d) => (
                              <div className={`list-box ${d.id === avatarId ? 'item-checked' : ''}`} key={d.id}>
                                {d.id === avatarId && <div ref={currentRef}></div>}
                                <div
                                  className="list-item"
                                  onClick={() => {
                                    setAvatarId(d.id)
                                    setGroupId(d.group_id)
                                  }}
                                >
                                  {d.id === avatarId && (
                                    <div className="checked">
                                      <CheckOutlined />
                                    </div>
                                  )}

                                  <div
                                    className="bg"
                                    style={{
                                      backgroundImage: `url(${urlSource(
                                        d.video_url,
                                        d.source_type === 3 ? '' : 'video'
                                      )})`
                                    }}
                                  ></div>
                                  <img src={urlSource(d.video_url, d.source_type === 3 ? '' : 'video')} />
                                </div>
                                <div className="name ellipsis">{d.title}</div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="list-empty">
                            <img className="empty" src={Empty} />
                            <p>当前数字人暂无形象</p>
                            <Button type="primary" onClick={() => setCreateModalOpen(true)}>
                              去添加
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <Radio.Group
                    value={avatarTab}
                    onChange={(e) => {
                      setAvatarTab(e.target.value)
                    }}
                    buttonStyle="solid"
                  >
                    <Radio.Button value={0}>我的数字人</Radio.Button>
                    <Radio.Button value={1}>公用数字人</Radio.Button>
                  </Radio.Group>
                  {avatarTab === 0 && (
                    <div className="avatar">
                      {groups.length ? (
                        <div className="list">
                          {groups.map((d) => (
                            <div
                              className={`list-group-box ${d.id === groupId ? 'actived' : ''}`}
                              key={d.id}
                              onClick={() => {
                                setGroup(d)
                              }}
                            >
                              <div className="list-group-item">
                                <div
                                  className="bg"
                                  style={{
                                    backgroundImage: `url(${d.cover_url})`
                                  }}
                                ></div>
                                <img src={d.cover_url} />
                              </div>
                              <div className="name ellipsis">{d.title}</div>
                              <div className="num">{d.member_count || 0}个形象</div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="list-empty">
                          <img className="empty" src={Empty} />
                          <p>你还没有数字人哦</p>
                          <Button type="primary" onClick={() => setCreateModalOpen(true)}>
                            去复刻
                          </Button>
                        </div>
                      )}
                    </div>
                  )}

                  {avatarTab === 1 && (
                    <div className="avatar">
                      <div className="list">
                        {globalGroups.map((d) => (
                          <div
                            className={`list-group-box ${d.id === groupId ? 'actived' : ''}`}
                            key={d.id}
                            onClick={() => {
                              setGroup(d)
                            }}
                          >
                            <div className="list-group-item">
                              <div
                                className="bg"
                                style={{
                                  backgroundImage: `url(${d.cover_url})`
                                }}
                              ></div>
                              <img src={d.cover_url} />
                            </div>
                            <div className="name ellipsis">{d.title}</div>
                            <div className="num">{d.member_count || 0}个形象</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>

        <div className="right">
          <div className="tabs">
            <Radio.Group
              value={model}
              onChange={(e) => {
                setModel(e.target.value)
              }}
              buttonStyle="solid"
            >
              <Radio.Button value="text">文本驱动</Radio.Button>
              <Radio.Button value="audio">音频驱动</Radio.Button>
            </Radio.Group>
          </div>

          {model === 'text' && (
            <>
              <div className="step-content">
                <div className="center">
                  <div className="header">
                    <label>台词</label>
                  </div>

                  <RichText onChange={onChange} onPreviewWords={previewWords} maxLength={maxLength as number} />

                  <div className="header">
                    <label>声音</label>
                  </div>

                  {selectTtsDetail && (
                    <>
                      <div className="choose-voice">
                        <div className="choose-voice__left">
                          <div className="left-img">
                            <img src={selectTtsDetail.group_cover_url} />
                          </div>
                          <div
                            className="play-pause"
                            onClick={(e) => {
                              e.stopPropagation()
                              previewTts(selectTts, '现在的一切都是为将来的梦想编织翅膀，让梦想在现实中展翅高飞。')
                            }}
                          >
                            {previewId === selectTts?.id ? (
                              playingId === selectTts?.id ? (
                                <PauseOutlined />
                              ) : (
                                <LoadingOutlined />
                              )
                            ) : (
                              <PlayGray class="play-icon" />
                            )}
                          </div>
                          <div className="detail">
                            <div className="name ellipsis">{selectTtsDetail.group_title}</div>
                            <div className="style ellipsis">{selectTtsDetail.title}</div>
                          </div>
                        </div>
                        <div className="choose-voice__right">
                          <Button type="primary" className="black" onClick={() => setVoiceModalOpen(true)}>
                            更换声音
                          </Button>
                          {selectTts.level === 20 && (
                            <Button onClick={() => setSettingPreview(selectTts)}>调整参数</Button>
                          )}
                        </div>
                      </div>
                      <div className="header high-header">
                        <label>高级选项</label>
                      </div>
                      <div className="high-setting">
                        <div className="setting-item">
                          <div className="title">
                            驱动模式
                            <Popover
                              content={
                                <div className="common-popover">
                                  <div>选择数字人视频在驱动时的模式：</div>
                                  <ul>
                                    <li>顺序驱动：使用数字人生成作品时会从你上传的原始视频第一帧开始顺序驱动</li>
                                    <li>
                                      随机模式：随机挑选片段来进行驱动
                                      {`（生成的音频时长超过原视频长度时，按照顺序驱动，不会走随机模式）`}
                                    </li>
                                  </ul>
                                </div>
                              }
                              placement="right"
                            >
                              <ExclamationCircleOutlined />
                            </Popover>
                          </div>
                          <Radio.Group onChange={onDriveModelChange} value={highSetting.drive_mode}>
                            <Radio value={0}>随机模式</Radio>
                            <Radio value={1}>顺序驱动</Radio>
                          </Radio.Group>
                        </div>
                        <div className="setting-item">
                          <div className="title">
                            输出设置
                            <Popover
                              content={
                                <div className="common-popover">
                                  <div>选择数字人视频在驱动时的模式：</div>
                                  <ul>
                                    <li>驱动视频：正常驱动数字人的视频作品</li>
                                    <li>
                                      仅音频：仅输出驱动后的音频文件，不做数字人驱动
                                      （输出音频的积分消耗值与所选声音有关，请查看{' '}
                                      <a
                                        style={{ textDecoration: 'underline' }}
                                        target="_blank"
                                        href="https://lingverse.feishu.cn/sheets/IJeQsahvdhqX5btBSzycBKPRnr0"
                                        rel="noreferrer"
                                      >
                                        积分说明
                                      </a>{' '}
                                      ）
                                    </li>
                                  </ul>
                                </div>
                              }
                              placement="right"
                            >
                              <ExclamationCircleOutlined />
                            </Popover>
                          </div>
                          <Radio.Group onChange={onAudioModelChange} value={highSetting.only_generate_audio}>
                            <Radio value={false}>驱动视频</Radio>
                            <Radio value={true}>仅音频</Radio>
                          </Radio.Group>
                        </div>

                        <div className="setting-item">
                          <div className="title">
                            生成模式
                            <Popover
                              content={
                                <div className="common-popover">
                                  <ul>
                                    <li>
                                      <a
                                        target="_blank"
                                        href="https://lingverse.feishu.cn/docx/VxnydIE4ao5NCyx7fnycqPX7npd#share-Evkidf4qYoA4AfxzoZJclzRkndh"
                                        rel="noreferrer"
                                        style={{ paddingLeft: 2, textDecoration: 'underline' }}
                                      >
                                        暗黑模式
                                      </a>
                                      ：创作不消耗积分
                                    </li>
                                    <li>积分模式：创作消耗积分</li>
                                  </ul>
                                </div>
                              }
                              placement="right"
                            >
                              <ExclamationCircleOutlined />
                            </Popover>
                          </div>
                          <Radio.Group onChange={onModeChange} value={highSetting.mode}>
                            <Radio value={1}>暗黑模式</Radio>
                            <Radio value={0}>积分模式</Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="footer-btn">
                  {highSetting.mode === 1 ? (
                    <Button className="btn-vip" loading={loading} type="primary" onClick={submitText}>
                      提交（不消耗积分）
                    </Button>
                  ) : (
                    <Button loading={loading} type="primary" onClick={submitText}>
                      提交
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}

          {model === 'audio' && (
            <>
              <div className="step-content">
                <div className="center">
                  <div className="warning">
                    <h4>音频要求</h4>
                    <div className="desc">
                      <div>
                        <label className="label">文件格式：</label>
                        <label>mp3、m4a、wav</label>
                      </div>
                      <div>
                        <label className="label">音频时长：</label>
                        <label>5秒～30分钟</label>
                      </div>
                    </div>
                  </div>

                  <div className="content">
                    {currentStep === 0 && (
                      <Dragger
                        accept=".mp3,.m4a,.wav"
                        showUploadList={false}
                        beforeUpload={async (file) => {
                          const flag = (await beforeAudioUpload(file)) as any
                          if (flag) {
                            setAudioFile(file)
                            uploadFile(file)
                          }
                          return flag
                        }}
                        onDrop={(e) => onDrop(e.dataTransfer.files?.[0] as any)}
                      >
                        <p className="ant-upload-drag-icon">
                          <CloudUploadOutlined />
                        </p>
                        <p className="ant-upload-text">上传音频, 驱动生成视频</p>
                        <p className="ant-upload-hint">将文件拖到此处，或点击此区域上传</p>
                      </Dragger>
                    )}

                    {currentStep === 1 && (
                      <div className="step-progress">
                        <div className="step-progress-content">
                          <div className="percent">{percent}%</div>
                          <Progress percent={percent} showInfo={false} />
                          <div className="tips">音频上传中</div>
                        </div>
                        <div className="btns">
                          <Button onClick={restartUpload}>取消</Button>
                        </div>
                      </div>
                    )}

                    {currentStep === 2 && (
                      <div className="step-view">
                        <div className="step-view-box">
                          <audio controls src={audioUrl} />
                          <div className="trash" onClick={restartUpload}>
                            <DeleteOutlined />
                          </div>
                        </div>
                        <div className="btns" onClick={restartUpload}>
                          <div>重新上传</div>
                        </div>
                      </div>
                    )}
                  </div>
                  {currentStep === 0 && <AudioDemos onSelect={selectDemoAudio} />}

                  <div className="header">
                    <label>高级选项</label>
                  </div>

                  <div className="high-setting">
                    <div className="setting-item">
                      <div className="title">
                        驱动模式
                        <Popover
                          content={
                            <div className="common-popover">
                              <div>选择数字人视频在驱动时的模式：</div>
                              <ul>
                                <li>顺序驱动：使用数字人生成作品时会从你上传的原始视频第一帧开始顺序驱动</li>
                                <li>随机模式：随机挑选片段来进行驱动</li>
                              </ul>
                            </div>
                          }
                          placement="right"
                        >
                          <ExclamationCircleOutlined />
                        </Popover>
                      </div>
                      <Radio.Group onChange={onDriveModelChange} value={highSetting.drive_mode}>
                        <Radio value={0}>随机模式</Radio>
                        <Radio value={1}>顺序驱动</Radio>
                      </Radio.Group>
                    </div>

                    <div className="setting-item">
                      <div className="title">
                        生成模式
                        <Popover
                          content={
                            <div className="common-popover">
                              <ul>
                                <li>
                                  <a
                                    target="_blank"
                                    href="https://lingverse.feishu.cn/docx/VxnydIE4ao5NCyx7fnycqPX7npd#share-Evkidf4qYoA4AfxzoZJclzRkndh"
                                    rel="noreferrer"
                                    style={{ paddingLeft: 2, textDecoration: 'underline' }}
                                  >
                                    暗黑模式
                                  </a>
                                  ：创作不消耗积分
                                </li>
                                <li>积分模式：创作消耗积分</li>
                              </ul>
                            </div>
                          }
                          placement="right"
                        >
                          <ExclamationCircleOutlined />
                        </Popover>
                      </div>
                      <Radio.Group onChange={onModeChange} value={highSetting.mode}>
                        <Radio value={1}>暗黑模式</Radio>
                        <Radio value={0}>积分模式</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>

                <div className="footer-btn">
                  {highSetting.mode === 1 ? (
                    <Button className="btn-vip" loading={loading} type="primary" onClick={submitAuduio}>
                      提交（不消耗积分）
                    </Button>
                  ) : (
                    <Button loading={loading} type="primary" onClick={submitAuduio}>
                      提交
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <VoiceSettingModal
        preview={settingPreview}
        onCancel={() => setSettingPreview(undefined as any)}
        onOk={() => {
          setSettingPreview(undefined as any)
          getSelfTts()
        }}
      />

      <VoiceModal
        open={voiceModalOpen}
        selectTts={selectTtsDetail}
        onCancel={() => setVoiceModalOpen(false)}
        upgradePlan={() => setPlanModalOpen(true)}
        onSelect={(t) => setSelectTts(t)}
      />

      <PayPointModal
        open={payPointModalOpen}
        onCancel={() => setPayPointModalOpen(false)}
        onSuccess={() => UserStore.updateUserPackage()}
      />

      <PlanModal
        open={planModalOpen}
        onCancel={() => setPlanModalOpen(false)}
        onSuccess={() => UserStore.updateUserPackage()}
      />

      <CreateAvatarDrawer
        group={group}
        open={createModalOpen}
        onCancel={() => setCreateModalOpen(false)}
        onOk={updateData}
      />
    </div>
  )
}

export default CreateVideo

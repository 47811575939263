import './style.scss'
import { Button, message, Modal } from 'antd'
import { FC, useEffect, useRef, useState } from 'react'
import * as Api from '@/api/home'
import { InteractStore, UserStore, WSStore } from '@/global-states'

interface IProps {
  onVideoReceive: (video: Uint8Array) => void
  onAudioReceive: (audio: Uint8Array) => void
  onStart: () => void
  onStop: () => void
  upgradePlan?: () => void
}

const Chatbox: FC<IProps> = (props) => {
  const { roomId, selectAvatar, selectTts, selectKnowLedge, interactStart, interactPendingNum } = InteractStore
  const { userPackage } = UserStore
  const { onVideoReceive, onAudioReceive, onStart, onStop, upgradePlan } = props
  const [recording, setRecording] = useState(true)
  const [loading, setLoading] = useState(false)
  const { interactWS } = WSStore
  const [wssUrl, setWssUrl] = useState('')
  const iRecorder = useRef<any>(null)
  const gumStream = useRef<any>(null)
  const audioOutputNode = useRef<any>(null)
  const timeRef = useRef<any>(null)

  useEffect(() => {
    if (!interactStart) {
      stopChat()
    }
  }, [interactStart])

  useEffect(() => {
    if (wssUrl) {
      WSStore.cleanInteractWS()
    }
    try {
      initWebSocket()
    } catch (e) {
      console.log('No web socket support in this browser!', e)
    }
  }, [wssUrl])

  useEffect(() => {
    if (interactWS) {
      initRecorder()
      initAudioOutputContext()
    }
  }, [interactWS])

  useEffect(() => {
    return () => stopChat()
  }, [])

  const getPlayingInfo = async (currentRoomId: number) => {
    const res = await Api.getPlayingInfo(currentRoomId)
    if (res.status === 1) {
      setWssUrl(res.websocket_url.replace('127.0.0.1', 'local-api.hifly.cc')) //TODO
    }
    if (res.position_in_queue) {
      InteractStore.interactPendingNum = res.position_in_queue
    }
    if (res.status === 2) {
      message.error('连接超时，请重新开始对话')
      stopChat()
    }
  }

  const startChat = async () => {
    if ((userPackage?.current_membership_level || 0) < 10) {
      return Modal.confirm({
        title: '会员专属',
        content: <div>实时互动数字人是会员专属功能，成为会员后可以使用实时互动数字人。</div>,
        okText: '开通会员',
        cancelText: '取消',
        onOk: () => {
          upgradePlan?.()
        }
      })
    }
    setLoading(true)
    let currentRoomId: any
    try {
      if (!roomId) {
        const res = await Api.addInteraction({
          avatar_id: selectAvatar?.id,
          tts_voice_id: selectTts?.id,
          agent_id: selectKnowLedge?.id
        })
        InteractStore.updateRoom(res.id)
        currentRoomId = res.id
      } else {
        currentRoomId = roomId
        await Api.editInteraction(roomId, {
          avatar_id: selectAvatar?.id,
          tts_voice_id: selectTts?.id,
          agent_id: selectKnowLedge?.id
        })
        await Api.stopInteraction(roomId)
      }

      await Api.startInteraction(currentRoomId, {
        start_kind: 6
      })

      onStart()

      timeRef.current = setInterval(async () => {
        getPlayingInfo(currentRoomId)
        interactWS?.send(JSON.stringify({ type: 3 })) //heartbeat
      }, 2000)
    } catch (e) {
      setLoading(false)
    }
  }

  const stopChat = () => {
    console.log('stopInteraction')
    if (timeRef.current) {
      clearInterval(timeRef.current)
    }
    stopRecording()
    gumStream.current?.getAudioTracks()[0].stop()
    onStop()
    iRecorder.current = null
    InteractStore.interactStart = false
    InteractStore.interactPendingNum = 0
    WSStore.cleanInteractWS()
    setWssUrl('')
    setLoading(false)
  }

  const initWebSocket = () => {
    if (!wssUrl) {
      return
    }
    const handler = interactWS ? interactWS : new WebSocket(wssUrl)
    WSStore.setInteractWS(handler)

    handler.onopen = function () {
      startRecording()
      setRecording(true)
    }

    handler.onmessage = function (e: any) {
      const data = e.data

      if (typeof data === 'string') {
        try {
          const json_data = JSON.parse(data)
          if (json_data.type === 100) {
            const streaming_info = json_data?.data?.streaming_info || ''
            console.log(streaming_info)
          }
        } catch (error) {
          console.error('parse JSON error', error)
        }
      } else if (data instanceof ArrayBuffer) {
        console.log('Received binary message (ArrayBuffer).')
      } else if (data instanceof Blob) {
        // console.log("Received binary message (Blob). ", data.length);
        const fileReader = new FileReader()
        fileReader.onload = function (event: any) {
          const arrayBuffer = event.target.result
          const dataView = new DataView(arrayBuffer)

          const data_type = dataView.getInt32(0, true)
          const remainingArrayBuffer = arrayBuffer.slice(4)
          if (data_type == 1) {
            // PCM audio
            audioOutputNode.current?.port.postMessage('clear')
            const samples = new Int16Array(remainingArrayBuffer)
            const floatSamples = new Float32Array(samples.length)
            for (let i = 0; i < samples.length; i++) {
              floatSamples[i] = samples[i] / 32768.0
            }
            audioOutputNode.current?.port.postMessage(floatSamples)
          } else if (data_type == 2) {
            const samples = new Int16Array(remainingArrayBuffer)
            const floatSamples = new Float32Array(samples.length)
            for (let i = 0; i < samples.length; i++) {
              floatSamples[i] = samples[i] / 32768.0
            }
            audioOutputNode.current?.port.postMessage(floatSamples)
          } else if (data_type == 3) {
            setLoading(false)
            // console.log("AVPacket H264");
            onVideoReceive(new Uint8Array(remainingArrayBuffer))
          } else if (data_type == 4) {
            // console.log("AVPacket AAC");
            onAudioReceive(new Uint8Array(remainingArrayBuffer))
          }
        }
        fileReader.readAsArrayBuffer(data)
      } else {
        console.log('Received unknown message type.')
      }
    }

    handler.onerror = function (event: any) {
      console.error('WebSocket error observed:', event)
      InteractStore.interactStart = false
      stopRecording()
    }

    handler.onclose = function (event: any) {
      InteractStore.interactStart = false
      console.log('WebSocket is closed now.', event)
    }
  }

  const initRecorder = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        echoCancellation: true,
        noiseSuppression: true,
        video: false
      } as any)
      .then(function (stream) {
        console.log('init Recorder ...')
        gumStream.current = stream

        iRecorder.current = new (window as any).Recorder(
          new AudioContext({ sampleRate: 16000 }).createMediaStreamSource(stream),
          {
            numChannels: 1,
            audioprocess: onAudioProcess
          }
        )

        if (recording) {
          startRecording()
        }

        console.log('init recorder ok')
      })
      .catch(function (err) {
        console.error('start media error', err)
      })
  }

  const onAudioProcess = (buffer: Blob) => {
    interactWS?.send(buffer)
  }

  const startRecording = async () => {
    iRecorder.current?.record()
    console.log('Recording started')
  }

  const stopRecording = () => {
    iRecorder.current?.stop()
    console.log('recorder stoped')
  }

  const initAudioOutputContext = async () => {
    const AudioContext = window.AudioContext || (window as any).webkitAudioContext
    const audioOutputContext = new AudioContext({ sampleRate: 16000 })
    try {
      await audioOutputContext.audioWorklet.addModule('/pcm.js')
      console.log('AudioWorklet module loaded.')
      audioOutputNode.current = new AudioWorkletNode(audioOutputContext, 'pcm-processor')
      audioOutputNode.current?.connect(audioOutputContext.destination)
    } catch (e) {
      console.error('Failed to load audio processor module', e)
    }
  }

  return (
    <div className="chat-box">
      <div className="title">开始对话吧</div>
      <div className="desc">
        为了确保您能体验到更好的对话效果，建议你保持周围环境安静，并在开始对话后授权系统麦克风权限。
      </div>
      <Button className="btn" type="primary" onClick={startChat} loading={loading}>
        {loading ? (interactPendingNum ? `排队中，前面还有${interactPendingNum}人` : '连线中') : '开始对话'}
      </Button>
      <div className="shadow"></div>
    </div>
  )
}

export default Chatbox

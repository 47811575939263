/* eslint-disable react/jsx-no-undef */
import './style.scss'
import { Dropdown, Modal } from 'antd'
import { FC, useEffect, useMemo, useState } from 'react'
import * as Api from '@/api/home'
import { Ellipsis } from '@/assets/svg'
import { InteractStore } from '@/global-states'
import { CheckOutlined, DeleteOutlined, EditOutlined, FileTextOutlined, PlusCircleFilled } from '@ant-design/icons'
import CreateKnowledgeDrawer from '../CreateKnowledgeDrawer'

interface IProps {
  open: boolean
  onCancel: () => void
}

const KnowledgeModal: FC<IProps> = (props) => {
  const { open, onCancel } = props
  const { selectKnowLedge } = InteractStore
  const tabs = ['我的智能体', '公共智能体']
  const [tabIndex, setTabIndex] = useState<number>()
  const [data, setData] = useState<any[]>([])
  const [globalData, setGlobalData] = useState<any[]>([])
  const [knowledgeDrawerOpen, setKnowledgeDrawerOpen] = useState(false)
  const [openId, setOpenId] = useState<any>()
  const [editId, setEditId] = useState<number>()

  const showData = useMemo(() => {
    if (tabIndex === 0) {
      return data
    }
    if (tabIndex === 1) {
      return globalData
    }
  }, [tabIndex, globalData, data])

  useEffect(() => {
    if (open) {
      getData()
    } else {
      setTabIndex(undefined)
    }
  }, [open])

  const getData = async () => {
    const res = await Api.getAgentList()
    setData((res?.list || []).filter((l: any) => !l.is_public))
    setGlobalData((res?.list || []).filter((l: any) => l.is_public))
    if (!tabIndex && tabIndex !== 0) {
      const selectData = (res.list || []).find((l: any) => l.id === selectKnowLedge?.id)
      if (selectData?.is_public) {
        setTabIndex(1)
      } else {
        setTabIndex(0)
      }
    }
  }

  const onTabChange = (index: number) => {
    setTabIndex(index)
  }

  const onSelect = (data: any) => {
    InteractStore.selectKnowLedge = data
    onCancel()
  }

  const editData = async (id: number) => {
    setKnowledgeDrawerOpen(true)
    setEditId(id)
  }

  const deleteData = async (id: number) => {
    await Api.deleteAgent(id)
    getData()
  }

  return (
    <Modal className="tab-modal modal-knowledge" open={open} footer={null} width={1020} onCancel={onCancel}>
      <div className="tab-modal-content">
        <div className="tabs">
          {tabs.map((t, index) => (
            <div
              className={`tabs-item ${tabIndex === index ? 'actived' : ''}`}
              key={t}
              onClick={onTabChange.bind(this, index)}
            >
              {t}
            </div>
          ))}
        </div>
        <div className="modal-list">
          {tabIndex === 0 && (
            <div
              className="list-add"
              onClick={() => {
                setEditId(undefined)
                setKnowledgeDrawerOpen(true)
              }}
            >
              <PlusCircleFilled />
              <div>创建智能体</div>
            </div>
          )}
          {showData?.map((d) => (
            <div
              className={`list-item ${d.id === selectKnowLedge?.id ? 'actived' : ''} ${
                openId === d.id ? 'hovered' : ''
              }`}
              key={d.id}
              onClick={onSelect.bind(this, d)}
            >
              {d.id === selectKnowLedge?.id && (
                <div className="checked">
                  <CheckOutlined />
                </div>
              )}
              {!d.is_public && (
                <div className="op" onClick={(e) => e.stopPropagation()}>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: '1',
                          label: (
                            <div className="dropdown-list" onClick={editData.bind(this, d.id)}>
                              <EditOutlined />
                              编辑
                            </div>
                          )
                        },
                        {
                          key: '2',
                          label: (
                            <div className="dropdown-list" onClick={deleteData.bind(this, d.id)}>
                              <DeleteOutlined />
                              删除
                            </div>
                          )
                        }
                      ]
                    }}
                    placement="bottom"
                    open={openId === d.id}
                    onOpenChange={(open: boolean) => setOpenId(open ? d.id : undefined)}
                  >
                    <div className="btn" onClick={(e) => e.stopPropagation()}>
                      <Ellipsis />
                    </div>
                  </Dropdown>
                </div>
              )}

              <FileTextOutlined />
              <div className="name ellipsis">{d.name}</div>
              {/* <div className="desc ellipsis">{d.description}</div> */}
            </div>
          ))}
        </div>
      </div>

      <CreateKnowledgeDrawer
        editId={editId}
        open={knowledgeDrawerOpen}
        onOk={getData}
        onCancel={() => setKnowledgeDrawerOpen(false)}
      />
    </Modal>
  )
}

export default KnowledgeModal
